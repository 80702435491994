<template>
  <div class="classify">
    <div class="top">
      <van-dropdown-menu>
        <van-dropdown-item
          @change="selectClassify"
          v-model="value1"
          :options="option1"
        />
        <van-dropdown-item
          @change="selectStatus"
          v-model="value2"
          :options="option2"
        />
      </van-dropdown-menu>
      <img src="../../img/market/add.png" alt="" @click="skipAdd" />
    </div>
    <div v-if="brandListInfo.length > 0">
      <div
        class="Smiddle banxin"
        v-for="(item, index) in brandListInfo"
        :key="index"
        @click="skipDetail(item.id, item.corp.name)"
      >
        <div class="left">
          <!-- <img :src="item.logo" alt="" /> -->
          <img :src="item.logo" alt="" v-if="item.logo" />
          <span v-else>{{ item.corp.name.slice(0, 1) }}</span>
        </div>
        <div class="right">
          <h1>{{ item.name }}</h1>
          <div class="you" v-if="item.status == '启用'">{{ item.status }}</div>
          <div class="hui" v-else>{{ item.status }}</div>
          <p>{{ item.introduction }}</p>
          <div class="bottom">
            <div class="bleft">
              <img :src="item.corp.logo" /><span>{{ item.corp.name }}</span>
            </div>
            <!-- <div class="p">100+人最近联系</div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-empty description="空空如也~" />
    </div>
    <!-- <div class="Smiddle banxin">
        <div class="left">
          <img src="../../img/index/3.jpg" alt="" />
        </div>
        <div class="right">
          <h1>品牌/主打名称</h1>
          <div class="tui">推</div>
          <p>系统开发、公众号运营、pos机支付、企业用品采购等</p>
          <div class="bottom">
            <div class="bleft">
              <img src="" /><span>深圳市齐声教育科技邮箱公司</span>
            </div>
            <div class="p">100+人最近联系</div>
          </div>
        </div>
      </div> -->
  </div>
</template>
<script>
import { qyIndexApi } from "../../api/qyIndex";
import { myApi } from "../../api/my";
export default {
  data() {
    return {
      qyName: "",
      value1: "",
      value2: "",
      option1: [
        { text: "品牌状态", value: "" },
        { text: "禁用", value: 0 },
        { text: "启用", value: 1 },
      ],
      option2: [
        { text: "审核状态", value: "" },
        { text: "已驳回", value: 0 },
        { text: "待审核", value: 1 },
        { text: "已通过", value: 2 },
      ],
      brandListInfo: [],
      // items: [
      //   {
      //     text: "浙江",
      //     children: [
      //       {
      //         text: "杭州",
      //       },
      //       {
      //         text: "温州",
      //       },
      //     ],
      //   },
      //   {
      //     text: "福建",
      //     children: [
      //       {
      //         text: "福州",
      //         children: [{ text: "鼓楼区" }, { text: "台江区" }],
      //       },
      //       {
      //         text: "厦门",
      //       },
      //     ],
      //   },
      // ],
      activeId: 1,
      activeIndex: 0,
    };
  },
  created() {
    this.getBrandList();
    this.getQyName();
  },
  methods: {
    getQyName() {
      myApi.myInfo().then((res) => {
        this.qyName = res.data.corpName;
      });
    },
    skipAdd() {
      this.$router.push({
        path: "/qyAddBrand/index",
        query: { name: this.qyName },
      });
    },
    skipDetail(id, name) {
      this.$router.push({ path: "/qyAddBrand/index", query: { id, name } });
    },
    selectClassify() {
      this.getBrandList();
    },
    selectStatus() {
      this.getBrandList();
    },
    getBrandList() {
      qyIndexApi
        .brandList({ status: this.value1, auditStatus: this.value2 })
        .then((res) => {
          console.log("品牌列表", res);
          this.brandListInfo = res.data.data;
          this.brandListInfo.map((res) => {
            if (res.status == 0) return (res.status = "禁用");
            else return (res.status = "启用");
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-menu {
  width: 70%;
}
/deep/ .van-dropdown-menu__bar {
  box-shadow: 0 0 !important;
}
@media screen and (min-width: 1024px){
  /deep/ .van-dropdown-item--down{
    overflow: hidden;
    margin-top: 25px !important;
  }
  /deep/ .van-popup--top{
    left: 18% !important;
  }
  .Smiddle {
    float: left;
    width: 48%;
    margin-right: 2%;
    min-height: 93px;
    overflow: hidden;
    background: #f2f8ff;
    border-radius: 5px;
    margin-bottom: 8px;
    .you{
      right: -26px;
    }
    .hui{
      right: -26px;
    }
    .right p{
      width: 100%;
      min-height: 20px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f8f8f;
      line-height: 19px;
      text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
  }

}
.top {
  display: flex;
  justify-content: space-around;
  height: 50px;
  border-bottom: 1px solid #979797;
  img {
    margin-top: 14px;
    width: 20px;
    height: 20px;
  }
}
.select {
  float: left;
  margin-top: 14px;
  border: none;
  width: 100px;
  height: 26px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  line-height: 19px;
  background-color: #fff;
}
.topRight {
  margin-top: 17px;
  float: left;
  height: 18px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  line-height: 19px;
}
.you {
  position: absolute;
  top: -4px;
  right: -7px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 59px;
  height: 18px;
  background: #f5a623;
  border-radius: 50px 0px 0px 50px;
}
.hui {
  position: absolute;
  top: -4px;
  right: -7px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 59px;
  height: 18px;
  background: #aaaaaa;
  border-radius: 50px 0px 0px 50px;
}
.Smiddle {
  overflow: hidden;
  margin-top: 14px;
  margin-bottom: 14px;
  min-height: 100px;
  background: #f2f8ff;
  border-radius: 5px;
  .left {
    float: left;
    width: 40px;
    margin: 14px 4px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      background-color: #3974c6;
      text-align: center;
    }
  }
  .right {
    position: relative;
    float: left;
    // width: 296px;
    width: 84%;
    margin-top: 14px;
    h1 {
      height: 21px;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 21px;
    }
    p {
      // width: 286px;
      width: 100%;
      min-height: 20px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f8f8f;
      line-height: 19px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
      .bleft {
        margin-top: -4px;
        height: 20px;
        line-height: 20px;
        span {
          display: inline-block;
          margin-top: -4px;
          height: 20px;
          line-height: 20px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
      .p {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8f8f8f;
      }
    }
  }
}
</style>