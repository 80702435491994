<template>
    <div class="market">
      <div class="banxin">
        <div class="top">
          <img src="../../img/market/jingshi.png" alt="" />
          <van-search
            class="input"
            v-model="inputSearch"
            placeholder="请输入搜索关键词"
            @search="onSearch"
          />
          <img
            src="../../img/market/add.png"
            alt=""
            @click="() => this.$router.push({ path: '/addChance/index' })"
          />
        </div>
        <div class="middle">
          <div class="Mtop">
            <div>
              <p>共 {{ needLength }} 个需求</p>
            </div>
            <div
              @click="
                () => {
                  this.$router.push('/filter/index');
                }
              "
            >
              <img src="../../img/market/shaixuan-2.png" alt="" />
            </div>
          </div>
          <!-- 发布的商机 -->
          <div class="chance" v-if="chanceList.length > 0">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div
                class="Cmiddle"
                v-for="(item, index) in chanceList"
                :key="index"
              >
                <div class="content" @click="skipDetail(item.id)">
                  <div class="ctop">
                    <h1>{{ item.name }}</h1>
                    <p>
                      {{ item.generalize }}
                    </p>
                  </div>
                  <div class="cCenter">
                    <div class="box">
                      <div class="m17">
                        <span>初步预算: </span>
                        <i
                          >{{ item.budget_range_start }} -
                          {{ item.budget_range_end }}</i
                        >
                      </div>
                      <div class="m17">
                        <span>需求时间: </span>
                        <span
                          >{{ item.create_time.slice(0, 10) }} -
                          {{ item.end_time.slice(0, 10) }}</span
                        >
                      </div>
                      <div class="m17">
                        <span>区域要求: </span> <span>{{ item.area }}</span>
                      </div>
                      <div class="m17">
                        <img :src="item.corp_logo" alt="" v-if="item.corp_logo" />
                        <span class="sp" v-else>{{
                          item.corp_name.slice(0, 1)
                        }}</span
                        ><em> {{ item.corp_name }}</em>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="bl">
                      <span>已抢：{{ item.grab_num }} 人</span>
                    </div>
                    <button
                      @click.stop="skipGrap(item.id)"
                      style="margin-top: -4px"
                    >
                      抢商机
                    </button>
                  </div>
                </div>
              </div>
            </van-list>
          </div>
          <div v-else>
            <van-empty description="暂无商机" />
          </div>
          <!-- 发布的商机 -->
          <!-- <div class="chance">
            <div class="Cmiddle">
              <div class="content">
                <div class="ctop">
                  <h1>品牌、主打名称</h1>
                  <p>
                    系统开发、公众号运营、pos机支付、企业用品采购等pos机支付、企业用品采购等
                  </p>
                </div>
                <div class="cCenter">
                  <div class="box">
                    <div class="m17"><span>初步预算:</span> <i>5万-6万</i></div>
                    <div class="m17">
                      <span>需求时间:</span> <span>广州市地方辅导费</span>
                    </div>
                    <div class="m17">
                      <span>区域要求:</span> <span>声震地方辅导费</span>
                    </div>
                    <div class="m17">
                      <img src="" alt="" /><em>深圳市齐声教育科技邮箱公司</em>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="bl"><span>已抢：3人</span></div>
                  <button style="margin-top: -4px">抢商机</button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

    </div>
  </template>
  <script>
  import { chanceApi } from "../../api/chance";
  
  export default {
    data() {
      return {
        needLength: 0,
        chanceList: [],
        inputSearch: "",
        loading: false,
        finished: false,
        total: 0,
        page: 1,
        filterArea: "",
        filterOrder: "",
        filterCategory: "",
      };
    },
    created() {
      this.filterArea = this.$route.query.area ? this.$route.query.area : "";
      this.filterOrder = this.$route.query.order ? this.$route.query.order : "";
      this.filterCategory = this.$route.query.category
        ? this.$route.query.category
        : "";
      sessionStorage.setItem("active", 1);
      this.getChanceList("");
    },
    methods: {
      onSearch() {
        let query = this.inputSearch;
        this.chanceList = [];
        this.getChanceList(query);
      },
      skipGrap(id) {
        this.$router.push({ path: "/grapMessage/index", query: { id } });
        return;
      },
      getChanceList(queryWord) {
        chanceApi
          .chanceList({
            page: this.page,
            queryWord,
            area: this.filterArea,
            order: this.filterOrder,
            category: this.filterCategory,
          })
          .then((res) => {
            console.log("商机列表", res);
            this.needLength = res.data.data.length;
            this.chanceList.push(...res.data.data); // 将数据放入list中
            console.log("this.chanceList", this.chanceList);
            this.loading = false;
            if (this.chanceList.length >= res.data.total) {
              this.finished = true; // 结束加载状态
            }
          });
      },
      // 被 @load调用的方法
      onLoad() {
        // 若加载条到了底部
        this.page++; // 分页数加一
        this.getChanceList(); // 调用上面方法,请求数据
        this.finished = false;
      },
      skipDetail(id) {
        this.$router.push({ path: "/chanceDetail/index", query: { id: id } });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  /deep/ .van-search__content {
    padding-left: 0;
  }
  .market {
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  // 顶部部分
  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin-top: 10px;
      width: 20px;
      height: 20px;
    }
    .input {
      margin-top: 10px;
      border: none;
      background: #f9f9f9;
      width: 83%;
      height: 36px;
      border-radius: 20px;
      font-size: 12px;
    }
  }
  // 内容部分
  .middle {
    flex: 1;
    overflow: auto;
    .Mtop {
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: -6px;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
    // 发布的商机
  
    // height: 351px;
    // margin-top: 10px;
    .Cmiddle {
      float: left;
      width:48%;
      margin-top: 10px;
      margin-right: 2%;
      min-height: 210px;
      background: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
      .content {
        margin: 9px auto;
        width: 94%;
        .ctop {
          width: 100%;
          min-height: 56px;
          h1 {
            // width: 315px;
            height: 21px;
            font-size: 15px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #4a4a4a;
            line-height: 21px;
          }
          p {
            // width: 334px;
            width: 100%;
            min-height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 20px;
             /*1. 先强制一行内显示文本*/
            white-space: nowrap;
            /*2. 超出的部分隐藏*/
            overflow: hidden;
            /*3. 文字用省略号替代超出的部分*/
            text-overflow: ellipsis;
          }
        }
        .cCenter {
          // width: 323px;
          width: 100%;
          height: 107px;
          background: #ffffff;
          border-radius: 5px;
          overflow: hidden;
          .box {
            // width: 313px;
            width: 96%;
            margin: 8px auto;
            margin-top: 4px;
            .m17 {
              height: 22px;
              line-height: 20px;
              img {
                width: 16px;
                height: 16px;
              }
              .sp {
                font-size: 12px;
                display: inline-block;
                width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                background-color: #3974c6;
                color: #fff;
              }
              span {
                height: 19px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #555555;
                line-height: 19px;
              }
              i {
                height: 20px;
                font-size: 14px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: #e84a10;
                line-height: 20px;
              }
              em {
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #555555;
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        height: 28px;
        justify-content: space-between;
        align-items: center;
        line-height: 28px;
        margin-top: 8px;
        .bl {
          font-size: 13px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e84a10;
          line-height: 20px;
        }
        button {
          border: none;
          font-size: 14px;
          color: #fff;
          width: 86px;
          height: 24px;
          line-height: 20px;
          background: #f5a623;
          border-radius: 12px;
        }
      }
    }
  
    i,
    em,
    b {
      font-style: normal;
    }
  }
  </style>
  