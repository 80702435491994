<template>
  <div class="myRight">
    <div class="top">
      <van-dropdown-menu>
        <van-dropdown-item
          @change="selectClassify"
          v-model="value1"
          :options="option1"
        />
        <van-dropdown-item
          @change="selectStatus"
          v-model="value2"
          :options="option2"
        />
      </van-dropdown-menu>
    </div>
    <div class="middle" v-if="myRightInfo.length > 0">
      <div
        v-for="(item, index) in myRightInfo"
        :key="index"
        @click.stop="skip(item.id, item.legalRight.name)"
      >
        <!-- 待使用 -->
        <div class="order" v-if="item.status == 0">
          <div class="Otop">
            <div class="banxin m16">
              <p>
                {{ item.consultServiceCategory.name }} -
                {{ item.consultService.name }}
              </p>
              <h2>待使用</h2>
            </div>
          </div>
          <div class="Omiddle">
            <div class="banxin m17">
              <div class="left">
                <div>
                  <span
                    >使用日期：{{ item.start_time.slice(0, 16) }} -
                    {{ item.end_time.slice(0, 16) }}</span
                  >
                </div>
                <div>
                  <span>赠送时间： {{ item.create_time.slice(0, 16) }}</span>
                </div>
              </div>
              <div class="right">
                <img src="../../img/index/jiantou.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bottom banxin">
            <div>
              <button class="address">申请使用</button>
            </div>
          </div>
        </div>
        <!-- 已使用 -->
        <div class="complete" v-if="item.status == 1">
          <div class="Otop">
            <div class="banxin m16">
              <p>
                {{ item.consultServiceCategory.name }} -
                {{ item.consultService.name }}
              </p>
              <h3
                style="
                  font-size: 13px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                "
              >
                已使用
              </h3>
            </div>
          </div>
          <div class="Omiddle" style="border-bottom: none">
            <div class="banxin m17">
              <div class="left">
                <div>
                  <span
                    >使用日期：{{ item.start_time.slice(0, 16) }} -
                    {{ item.end_time.slice(0, 16) }}</span
                  >
                </div>
                <div>
                  <span>预约时间：{{ item.create_time.slice(0, 16) }}</span>
                </div>
              </div>
              <div class="right">
                <img src="../../img/index/jiantou.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 已过期 -->
        <div class="complete" v-if="item.status == -1">
          <div class="Otop">
            <div class="banxin m16">
              <p>
                {{ item.consultServiceCategory.name }} -
                {{ item.consultService.name }}
              </p>
              <h3
                style="
                  font-size: 13px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                "
              >
                已过期
              </h3>
            </div>
          </div>
          <div class="Omiddle" style="border-bottom: none">
            <div class="banxin m17">
              <div class="left">
                <div>
                  <span
                    >使用日期：{{ item.start_time.slice(0, 16) }} -
                    {{ item.end_time.slice(0, 16) }}</span
                  >
                </div>
                <div>
                  <span>赠送时间：{{ item.create_time.slice(0, 16) }}</span>
                </div>
              </div>
              <div class="right">
                <img src="../../img/index/jiantou.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-empty description="空空如也~" />
    </div>
  </div>
</template>
<script>
import { myRightApi } from "../../api/myRight";
export default {
  data() {
    return {
      myRightInfo: [],
      value1: 0,
      value2: "",
      option1: [],
      option2: [
        { text: "权益状态", value: "" },
        { text: "已过期", value: -1 },
        { text: "待使用", value: 0 },
        { text: "已使用", value: 1 },
      ],
    };
  },
  created() {
    this.getData();
    this.getRightType();
  },
  methods: {
    selectClassify() {
      this.getData();
    },
    selectStatus() {
      this.getData();
    },
    getData() {
      myRightApi
        .myRight({ consultServiceId: this.value1, status: this.value2 })
        .then((res) => {
          console.log("我的权益", res);
          this.myRightInfo = res.data.data;
        });
    },
    getRightType() {
      myRightApi.rightType().then((res) => {
        res.data.unshift({ id: "", name: "权益类型" });
        console.log("权益类型1", this.option1);
        this.option1 = res.data.map((item) => {
          return { text: item.name, value: item.id };
        });
        this.value1 = this.option1[0]?.value;

        // console.log('权益类型2',typeof(this.option1))
      });
    },
    skip(id, rightName) {
      this.$router.push({
        path: "/myRightDetail/index",
        query: { id, rightName },
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-item--down {
  margin-top: -17px;
}
@media screen and(min-width: 1024px) {
  .complete,.order {
    padding: 4px;
    width: 32% !important;
    margin-left: 1%;
    float: left;
    // min-height: 174px !important;
  }
  /deep/ .van-dropdown-item--down{
    overflow: hidden;
    margin-top: 25px !important;
  }
  /deep/ .van-popup--top{
    left: 18% !important;
  }
}
.myRight {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    height: 44px;
    overflow: hidden;
  }
}
.Otop {
  height: 36px;
  border-bottom: 1px solid #dbdbdb;
  .m16 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;

    p {
      margin: 0;
      height: 18px;

      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    h2 {
      margin: 0;
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e84a10;
    }
  }
}
.Omiddle {
  height: 86px;
  border-bottom: 1px solid #dbdbdb;
  .m17 {
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: 20px;
      height: 80px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
    }
    .right {
      margin-top: 10px;
      height: 80px;
      line-height: 58px;
      img {
        width: 8px;
        height: 8px;
      }
    }
  }
}
.middle {
  .order {
    margin-top: 10px;
    width: 100%;
    height: 164px;
    background-color: #fff;
  }
  .bottom {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    .cancel {
      border: none;
      font-size: 13px;
      width: 86px;
      height: 24px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #c1c9d0;
      color: #c1c9d0;
      margin-right: 5px;
    }
    .address {
      border: none;
      width: 86px;
      height: 24px;
      background: #f5a623;
      border-radius: 12px;
      font-size: 13px;
      color: #fff;
    }
  }
}

.complete {
  margin-top: 10px;
  width: 100%;
  height: 116px;
  background-color: #fff;
}
</style>