<template>
  <div class="index">
    <div class="banxin">
      <div class="top">
        <img
          src="../../img/index/fenlei.png"
          alt=""
          @click="
            () => {
              this.$router.push('/filter/index');
            }
          "
        />
        <van-search
          class="input"
          v-model="inputSearch"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        />
      </div>
      <div style="width: 100%; height: 170px">
        <div class="banner">
          <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in banner" :key="index"
              ><div v-if="item.content_type == 1">
                <img :src="item.picture" @click="showCont(item.content)" />
              </div>
              <a v-else :href="item.content"> <img :src="item.picture" /></a>
            </van-swipe-item>
          </van-swipe>
        </div>

        <!-- 中间部分 -->
        <div class="middle">
          <div class="left" v-for="(item, index) in advertisement" :key="index">
            <!-- <div
                class="content"
                @click="
                  () => {
                    this.$router.push({ path: '/market/index' });
                  }
                "
              >
                <div class="m16">
                  <p>寻找商机</p>
                </div>
                <span>寻找商机寻找商机</span>
                <button>了解详情</button>
              </div> -->
            <div v-if="item.content_type == 1">
              <img :src="item.picture" @click="showCont(item.content)" />
            </div>
            <a v-else :href="item.content"> <img :src="item.picture" /></a>
          </div>
          <!-- <div
              class="right"
              @click="
                () => {
                  this.$router.push({ path: '/findCustom/index' });
                }
              "
            >
              <div class="content">
                <div class="m16">
                  <p>发现客户</p>
                </div>
                <span>寻找潜在客户</span>
                <button>了解详情</button>
              </div>
            </div> -->
        </div>
      </div>
      <!-- 资源搜索 -->
      <div class="source">
        <div class="Stop">
          <div class="left">资源搜索</div>
          <div
            class="right"
            @click="() => this.$router.push({ path: '/classify/index' })"
          >
            查看更多 &nbsp;>
          </div>
        </div>
        <div class="Smiddle" v-for="(item, index) in brandInfo" :key="index">
          <div class="left">
            <img :src="item.logo" alt="" v-if="item.logo" />
            <span v-else>{{ item.corp_name.slice(0, 1) }}</span>
          </div>
          <div class="right" @click="skipDetail(item.id)">
            <h1>{{ item.name }}</h1>
            <p>{{ item.introduction }}</p>
            <div class="bottom">
              <div class="bleft">
                <img :src="item.corp_logo" /><span>{{ item.corp_name }}</span>
              </div>
              <!-- <div class="p">100+人最近联系</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 发布的商机 -->
      <div class="chance">
        <div class="Ctop">
          <div class="left">历史合作企业发布的商机</div>
          <div class="right">
            <button :class="[isSelect ? 'act' : 'unAct']" @click="change">
              本周内
            </button>
            <button :class="[isSelect2 ? 'act' : 'unAct']" @click="change2">
              本月内
            </button>
          </div>
        </div>
        <div class="Cmiddle" v-if="chanceListInfo.length > 0">
          <div
            class="content"
            v-for="(item, index) in chanceListInfo"
            :key="index"
          >
            <div class="ctop">
              <h1>{{ item.name }}</h1>
              <p>
                {{ item.generalize }}
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>初步预算:</span>
                  <i
                    >{{ item.budget_range_start }}-{{
                      item.budget_range_end
                    }}</i
                  >
                </div>
                <div class="m17">
                  <span>需求时间:</span>
                  <span>{{ item.start_time }}-{{ item.end_time }}</span>
                </div>
                <div class="m17">
                  <span>区域要求:</span> <span>声震地方辅导费</span>
                </div>
                <div class="m17">
                  <img :src="item.corp_logo" alt="" /><em>{{
                    item.corp_name
                  }}</em>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="bl"><span>已抢：3人</span></div>
              <button @click.stop="skipGrap(item.id)" style="margin-top: -4px">
                抢商机
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无商机~" />
        </div>
      </div>
      <van-popup v-model="showBannerCont" v-html="content"></van-popup>
      <div style="padding-bottom: 50px"></div>
    </div>
  </div>
</template>
  <script>
import { indexApi } from "@/api/index";
import { chanceApi } from "../../api/chance";
import { brandApi } from "../../api/brand";
import store from "../../store";
export default {
  data() {
    return {
      content: "",
      showBannerCont: false,
      isSelect2: false,
      isSelect: true,
      // 首页banner图
      banner: [],
      //首页专区
      advertisement: [],
      brandInfo: [],
      queryPeriod: 1,
      inputSearch: "",
      queryPeriod: 2,
      chanceListInfo: [],
    };
  },
  created() {
    // store.dispatch('JudgeAdmin')
    sessionStorage.setItem("active", 0);
    this.getData();
    this.getChanceList();
    this.getBrandList();
  },
  methods: {
    showCont(cont) {
      this.content = cont;
      this.showBannerCont = true;
    },
    skipGrap(id) {
      this.$router.push({ path: "/grapMessage/index", query: { id } });
      return;
    },
    onSearch() {
      this.$router.push({
        path: "/search/index",
        query: { search: this.inputSearch },
      });
    },
    // 获取banner图和广告
    getData() {
      indexApi.homepage().then((res) => {
        console.log("banner", res);
        this.banner = res.data.banner;
        this.advertisement = res.data.area.slice(0, 2);
      });
    },
    // 获取品牌列表
    getBrandList() {
      indexApi.recommendBrand().then((res) => {
        console.log("brand", res);
        this.brandInfo = res.data;
        console.log("this.brandInfo", this.brandInfo);
      });
    },
    // 获取和企业合作的商机
    getChanceList() {
      chanceApi
        .chanceList({ workTogether: 1, queryPeriod: this.queryPeriod })
        .then((res) => {
          console.log("商机", res);
          this.chanceListInfo = res.data.data;
        });
    },
    skipDetail(id) {
      this.$router.push({ path: "/brandInfo/index", query: { id } });
    },
    change() {
      this.isSelect = true;
      this.isSelect2 = false;
      this.queryPeriod = 1;
      this.getChanceList();
    },
    change2() {
      this.isSelect = false;
      this.isSelect2 = true;
      this.queryPeriod = 2;
      this.getChanceList();
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/ .van-search__content {
  padding-left: 0;
  border-radius: 18px;
}
/deep/ .van-popup--center {
  width: 60%;
  height: 180px;
  left: 50% !important;
}
.index {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
// 顶部部分
.top {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    margin-top: 10px;
    width: 20px;
    height: 15px;
  }
  .input {
    margin-top: 10px;
    border: none;
    text-indent: 6px;
    width: 35%;
    height: 22px;
  }
}
// 轮播图部分
.banner {
  float: left;
    width: 70%;
    height: 170px;
    margin-right: 13px;
  img {
    width: 100%;
    height: 170px;
    border-radius: 10px;
  }
}
// 中间部分
.middle {
  float: left;
  display: flex;
  flex-direction: column;
  width: 26%;
  .left {
    width: 100%;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 10px;
    img {
      border-radius: 10px;
      width: 100%;
      height: 80px;
    }
  }
  .right {
    width: 100%;
    height: 80px;
    background: linear-gradient(126deg, #bcbcff 0%, #7575fc 100%);
    border-radius: 10px;
  }
  .content {
    // width: 140px;
    width: 82%;
    height: 60px;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    .m16 {
      width: 82%;
      height: 20px;
      display: flex;
      justify-content: space-between;
    }
    p {
      font-size: 16px;
      color: #fff;
    }
    h1 {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #fff;
    }
    span {
      display: inline-block;
      margin: 6px 0 8px 0;
      font-size: 12px;
      color: #fff;
    }
    button {
      border: none;
      width: 64px;
      height: 16px;
      background: #ffffff;
      border-radius: 7px;
      color: #fba4a9;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.act {
  color: #fff !important;
  background-color: #3974c6 !important;
}
.unAct {
  border: none !important;
  color: #fff !important;
  background-color: #d8d8d8 !important;
}
// 资源搜索部分
.source {
  min-height: 323px;
  .Stop {
    width:98%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
    .right {
      font-size: 12px;
      font-family: PingFang-SC-ExtraLight, PingFang-SC;
      img {
        width: 10px;
        height: 10px;
        margin-left: 8px;
        line-height: 21px;
      }
    }
  }
  .Smiddle {
    float: left;
    width: 48%;
    margin-right: 2%;
    min-height: 93px;
    overflow: hidden;
    background: #f2f8ff;
    border-radius: 5px;
    margin-bottom: 8px;
    .left {
      float: left;
      width: 40px;
      margin: 14px 4px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        background-color: #3974c6;
        text-align: center;
      }
    }
    .right {
      min-height: 37px;
      float: left;
      width: 84%;
      margin-top: 12px;
      h1 {
        height: 21px;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 21px;
      }
      p {
        width: 98%;
        min-height: 37px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8f8f8f;
        line-height: 17px;
        text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .bottom {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        height: 20px;
        line-height: 20px;
        .bleft {
          margin-top: -4px;
          height: 20px;
          line-height: 20px;
          span {
            display: inline-block;
            margin-top: -4px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
        .p {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8f8f8f;
        }
      }
    }
  }
}
// 发布的商机
.chance {
  min-height: 232px;
  .Ctop {
    width: 98%;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
    .right {
      width: 15%;
      button {
        width: 50%;
        height: 20px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #3974c7;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3974c6;
        line-height: 15px;
      }
    }
  }
  .Cmiddle {
    float: left;
      width:48%;
      margin-top: 10px;
      margin-right: 2%;
      min-height: 210px;
      background: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
    .content {
      margin: 9px auto;
      width: 94%;
      .ctop {
        width: 100%;
        min-height: 56px;
        h1 {
          width: 100%;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          width: 100%;
          min-height: 48px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
          /*1. 先强制一行内显示文本*/
          white-space: nowrap;
          /*2. 超出的部分隐藏*/
          overflow: hidden;
          /*3. 文字用省略号替代超出的部分*/
          text-overflow: ellipsis;
        }
      }
      .cCenter {
        width: 100%;
        min-height: 107px;
        background: #ffffff;
        border-radius: 5px;
        .box {
          width: 97%;
          margin: 8px auto;
          .m17 {
            height: 20px;
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }
}
i,
em,
b {
  font-style: normal;
}
</style>
  