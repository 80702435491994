<template>
  <div class="realTimeInfo">
    <!-- <div class="top">
      <div class="left">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" />
        </van-dropdown-menu>
      </div>
      <div class="right">最近发布</div>
    </div> -->
    <div class="banxin">
    <div v-if="info.length>0">
      <div class="content" v-for="(item,index) in info" :key="index" @click="skip(item.id)">
        <div class="left">
          <img :src="item.image" alt="" />
        </div>
        <div class="right">
          <h5>{{item.name}}</h5>
          <span>{{item.summary}}</span>
        </div>
      </div>
      
    </div>
    <div v-else>
      <van-empty description="空空如也~" />
    </div>
  </div>
  </div>
</template>
<script>
import {hyInfoApi} from '../../api/hyInfo'
export default {
  data() {
    return {
      value1: 0,
      info:[],
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
    };
  },
  created(){
    this.getHyInfo()
  },
  methods: {
    getHyInfo(){
      hyInfoApi.hyInfoList().then(res=>{
        console.log('res',res)
        this.info=res.data.data
      })
    },
    skip(id){
      this.$router.push({path:'/realTimeInfo/detail',query:{id}})
    }
  },
};
</script>
<style lang="less" scoped>
.realTimeInfo {
  background-color: #f9f9f9;
  min-height: 500px;
}
.top {
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  overflow: hidden;
  .left {
    float: left;
    width: 50%;
  }
  .right {
    height: 46px;
    float: left;
    width: 50%;
    line-height: 44px;
    text-align: center;
  }
}
.banxin {
  margin-top: 13px;
  min-height: 99px;
  background-color: #fff;

  border-radius: 5px;
  overflow: hidden;
  .content {
    width: 89%;
    min-height: 70px;
    
    margin: 15px;
    .left {
      float: left;
    }
    img {
      width: 60px;
      height: 70px;
    }
    .right {
      float: left;
      width: 69%;
      margin-left: 25px;
      h5 {
        height: 15px;
        font-size: 15px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #000000;
      }
      span {
        display: inline-block;
        margin-top: 4px;
        height: 38px;
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #717174;
        line-height: 19px;
      }
    }
  }
}
</style>