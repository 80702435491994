<template>
  <div class="helpCenter">
    <div class="banxin">
     <div v-html="info" style="margin-top:6px"></div>
    </div>
  </div>
</template>
<script>
import { myApi } from '../../api/my';
export default {
  data() {
    return {
      info:[],
      id:''
    };
  },
  created(){
    this.getData()
  },
  methods: {
    getData(){
      this.id = this.$route.query.id;
      myApi.helpCenterDetail({id:this.id}).then(res=>{
        console.log('帮助中心详情',res)
        this.info=res.data
      })
    }
  },
};
</script>
<style lang="less" scoped>
@media screen and(min-width: 1024px) {
  .helpCenter{
    height: 500px;
  }
}
.banxin {
  overflow: hidden;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  height: 80px;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .left {
    width:86%;
    
    h5 {
      margin:10px;
      height: 16px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #000000;
      line-height: 24px;
    }
    span {
      margin-left: 10px;
      display: inline-block;
      height: 40px;
      font-size: 13px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #7f7f7f;
      line-height: 20px;
    }
  }
  .right {
    margin-top: -11px;
    button {
      border: none;
      width: 24px;
      height: 80px;
      background: #3974c7;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
      border-radius: 0px 4px 4px 0px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ffffff;
    }
  }
}
</style>