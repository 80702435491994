<template>
    <div class="companyDetail">
      <!-- 企业信息 -->
      <div style="min-height:180px;width: 100%;overflow: auto;">
      <div class="company banxin" style="width:61%;float:left">
        <div class="Stop">
          <div class="left">企业信息</div>
        </div>
        <div class="Bmiddle">
          <div class="Bmcontent">
            <div>
              <span>企业全称：{{ companyDetailInfo.name }}</span>
            </div>
            <div>
              <span>企业地址：{{ companyDetailInfo.address }}</span>
            </div>
            <div>
              <span>行业类型：{{ companyDetailInfo.industry_category }}</span>
            </div>
            <div>
              <span>员工规模：{{ companyDetailInfo.corp_scale }}</span>
            </div>
            <div>
              <span
                >企业官网：<a>{{
                  companyDetailInfo.official_website.slice(0, 46)
                }}</a></span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 企业联系人 -->
      <div class="contact banxin">
        <div class="Stop">
          <div class="left">企业联系人</div>
        </div>
        <div v-if="companyContactsInfo.length > 0" style="height:133px;overflow:auto">
          <div
            class="Cmiddle"
            v-for="(item, index) in companyContactsInfo"
            :key="index"
          >
            <div class="Cmcontent">
              <div class="left" style="margin-top: -6px">
                <img :src="item.avatar" alt="" /><span
                  >&nbsp;&nbsp;&nbsp;<ww-open-data
                    type="userName"
                    :openid="item.name"
                    :corpid="item.corp_id"
                /></span>
              </div>
              <div class="right" style="margin-top: -6px">
                <button @click="Add(item.id)">申请添加</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无联系人~" style="padding: 0" />
        </div>
      </div>
    </div>
      <!-- 企业品牌 -->
      <div class="company banxin">
        <div class="Stop">
          <div class="left">企业品牌</div>
        </div>
        <div v-if="companyBrandInfo.length > 0" style="width:100%;min-height:50px;overflow:auto">
          <div
            class="Smiddle"
            v-for="(item, index) in companyBrandInfo"
            :key="index"
            @click="skipBrandDetail(item.id)"
          >
            <div class="you" v-if="item.recommend != ''">
              {{ item.recommend }}
            </div>
            <div class="left">
              <img :src="item.logo" alt="" v-if="item.logo" />
              <span v-else>{{ item.corp_name.slice(0, 1) }}</span>
            </div>
            <div class="right">
              <h1>{{ item.name }}</h1>
              <p>{{ item.introduction }}</p>
              <div class="bottom">
                <div class="bleft">
                  <img src="" /><span>
                    {{ item.corp_name }}
                  </span>
                </div>
                <!-- <div class="p">100+人最近联系</div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无品牌信息~" style="padding: 0" />
        </div>
      </div>
      
      <!--企业商机 -->
      <div class="chance banxin">
        <div class="Ctop">
          <div class="left">企业商机</div>
          <!-- <div class="right">
                <button>本周内</button>
                <button style="border-left: ">本月内</button>
              </div> -->
        </div>
        <div v-if="companyChanceInfo.length > 0">
          <div
            class="Cmiddle"
            v-for="(item, index) in companyChanceInfo"
            :key="index"
            @click="skipChanceDetail(item.id)"
          >
            <div class="content">
              <div class="ctop">
                <h1>{{ item.name }}</h1>
                <p>
                  {{ item.generalize }}
                </p>
              </div>
              <div class="cCenter">
                <div class="box">
                  <div class="m17">
                    <span>初步预算:</span>
                    <i
                      >{{ item.budget_range_start }} -
                      {{ item.budget_range_end }}</i
                    >
                  </div>
                  <div class="m17">
                    <span>需求时间:</span>
                    <span
                      >{{ item.start_time.slice(0, 16) }} -
                      {{ item.end_time.slice(0, 16) }}</span
                    >
                  </div>
                  <div class="m17">
                    <span>区域要求:</span> <span>{{ item.area }}</span>
                  </div>
                  <div class="m17">
                    <img :src="item.corp_logo" alt="" /><em>{{
                      item.corp_name
                    }}</em>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="bl">
                  <span>已抢：{{ item.grap_num ? item.grap_num : 0 }}人</span>
                </div>
                <button style="margin-top: -4px" @click="skipGrap(item.id)">
                  抢商机
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无商机~" style="padding: 0" />
        </div>
      </div>
      <!-- 企业评价 -->
      <!-- <div class="pingjia banxin">
        <div class="Stop">
          <div class="left">企业评价</div>
        </div>
        <div class="Pmiddle">
          <div class="Pmcontent">
            <div class="custom">
              <div style="width: 40px">客户：</div>
              <div>反倒是卡发电机房大富科技的咖啡对方答复发发呆发放大法</div>
            </div>
            <div class="res">
              <span>&nbsp;&nbsp;&nbsp;回复：</span>
              <i
                >大幅度开发费大富科技地方科技大疯狂打反间谍法地方科技大疯狂打反间谍法</i
              >
            </div>
            <div class="release">
              <input type="text" placeholder="请输入评论内容" />
              <button class="btn1">发布</button>
            </div>
            暂无评价
           <p>暂无评价~</p>
            <div class="inp">
              <input type="text" placeholder="请输入评论内容" />
              <button class="btn2">发布</button>
            </div> 
          </div>
        </div>
      </div> -->
    </div>
  </template>
  <script>
  import { companyApi } from "../../api/company";
  import { initAgentConfig } from "../../utils/wxCodeAuth";
  
  export default {
    data() {
      return {
        companyDetailInfo: "",
        companyBrandInfo: "",
        companyContactsInfo: "",
        companyChanceInfo: "",
      };
    },
    created() {
      this.getcompanyDetail();
      initAgentConfig();
    },
    methods: {
      getcompanyDetail() {
        const brandId = location.search.split("=")[1];
        companyApi.companyDetail({ corpId: brandId }).then((res) => {
          console.log("企业详情", res);
          this.companyDetailInfo = res.data.detail;
          this.companyBrandInfo = res.data.brand.data;
          this.companyContactsInfo = res.data.contacts.data;
          this.companyChanceInfo = res.data.businessOpportunities.data;
        });
      },
      skipChanceDetail(id) {
        this.$router.push({ path: "/chanceDetail/index", query: { id: id } });
      },
      skipGrap(id, event) {
        event.stopPropagation();
        this.$router.push({ path: "/grapMessage/index", query: { id } });
      },
      skipBrandDetail(id) {
        this.$router.push({ path: "/brandInfo/index", query: { id: id } });
      },
      Add(id) {
        companyApi.applyAdd({ id }).then((res) => {
          console.log("添加");
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .companyDetail {
    width: 100%;
    background-color: #fff;
  }
  .Stop {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3da1fe;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }
  // 企业信息部分
  .Bmiddle {
    margin-right: 10px;
    min-height: 129px;
    background: #f2f8ff;
    border-radius: 4px;
    .Bmcontent {
      div {
        min-height: 24px;
        line-height: 18px;
      }
      margin-left: 10px;
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
      }
    }
  }
  // 企业品牌部分
  .company {
    .you {
      position: absolute;
      top: 10px;
      right: 0;
      text-align: center;
      color: #fff;
      font-size: 12px;
      width: 59px;
      height: 18px;
      background: #f5a623;
      border-radius: 50px 0px 0px 50px;
    }
    .Smiddle {
      position: relative;
      // width: 93%;
      min-height: 118px;
      background: #f2f8ff;
      border-radius: 5px;
      margin-bottom: 10px;
      overflow: hidden;
      width:48%;
      float: left;
      .left {
        float: left;
        width: 40px;
        margin: 14px 4px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        span {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          color: #fff;
          background-color: #3974c6;
          text-align: center;
        }
      }
      .right {
        float: left;
        // width: 296px;
        width: 84%;
        margin-top: 12px;
        h1 {
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          // width: 286px;
          width: 100%;
          min-height: 37px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8f8f8f;
          line-height: 18px;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          height: 20px;
          line-height: 20px;
          margin-bottom: 12px;
  
          .bleft {
            margin-top: -4px;
            height: 20px;
            line-height: 20px;
            span {
              display: inline-block;
              margin-top: -4px;
              height: 20px;
              line-height: 20px;
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
          .p {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8f8f8f;
          }
        }
      }
    }
  }
  // 企业联系人
  .contact {
    float: left;
    width: 38%;
    
    .Cmiddle {
     
      min-height: 62px;
      background: #f2f8ff;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .Cmcontent {
      width: 92%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      min-height: 62px;
      align-items: center;
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #555555;
      }
      button {
        border: none;
        width: 86px;
        height: 24px;
        background: #3974c6;
        border-radius: 12px;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  // 发布的商机
  .chance {
    min-height: 232px;
    .Ctop {
      min-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 21px;
        padding-left: 8px;
        border-left: 2px solid #3974c6;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #000000;
        line-height: 21px;
      }
      .right {
        button {
          width: 54px;
          height: 20px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #3974c7;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3974c6;
          line-height: 15px;
        }
      }
    }
    .Cmiddle {
      float: left;
      width:48%;
      margin-right: 2%;
      min-height: 210px;
      background: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 10px;
      .content {
        margin: 9px auto;
        width: 94%;
        .ctop {
          width: 100%;
          min-height: 50px;
          h1 {
            width: 100%;
            height: 21px;
            font-size: 15px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #4a4a4a;
            line-height: 21px;
          }
          p {
            width: 100%;
            min-height: 20px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
            /*1. 先强制一行内显示文本*/
          white-space: nowrap;
          /*2. 超出的部分隐藏*/
          overflow: hidden;
          /*3. 文字用省略号替代超出的部分*/
          text-overflow: ellipsis;
          }
        }
        .cCenter {
          width: 100%;
          min-height: 107px;
          background: #ffffff;
          border-radius: 5px;
          .box {
            width: 97%;
            margin: 8px auto;
            .m17 {
              height: 20px;
            }
            span {
              height: 19px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
              line-height: 19px;
            }
            i {
              height: 20px;
              font-size: 14px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #e84a10;
              line-height: 20px;
            }
            em {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
        }
      }
      .bottom {
        display: flex;
        height: 28px;
        justify-content: space-between;
        align-items: center;
        line-height: 28px;
        margin-top: 8px;
        .bl {
          font-size: 13px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e84a10;
          line-height: 20px;
        }
        button {
          border: none;
          font-size: 14px;
          color: #fff;
          width: 86px;
          height: 24px;
          line-height: 20px;
          background: #f5a623;
          border-radius: 12px;
        }
      }
    }
  }
  // 企业评价
  .pingjia {
    ::-webkit-input-placeholder {
      color: #c1c1c1;
      font-size: 12px;
      height: 20px;
      padding-bottom: 10px;
    }
    .Pmiddle {
      min-height: 124px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    .Pmcontent {
      overflow: hidden;
      width: 95%;
      min-height: 152px;
      margin: 0 auto;
      border-bottom: 1px solid #d8d8d8;
      .custom {
        margin-top: 13px;
        display: flex;
        min-height: 36px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #515151;
        line-height: 20px;
      }
      .res {
        display: flex;
        width: 96%;
        margin: 13px 0 13px 10px;
        min-height: 51px;
        background: #ffffff;
        span {
          width: 79px;
          color: #509dec;
          font-size: 12px;
          margin-top: 4px;
        }
        i {
          height: 33px;
          color: #515151;
          font-size: 12px;
          line-height: 17px;
          margin-top: 4px;
        }
      }
      .release {
        display: flex;
        align-items: center;
        height: 36px;
        background: #ffffff;
        margin: 5px 0;
        input {
          margin-left: 10px;
          border: none;
          width: 78%;
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #eeeeee;
          text-indent: 8px;
        }
  
        .btn1 {
          margin-left: 10px;
          border: none;
          text-align: center;
          width: 40px;
          height: 32px;
          background: #3974c6;
          border-radius: 4px;
          font-size: 13px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          color: #ffffff;
        }
      }
      p {
        height: 104px;
        line-height: 104px;
        font-size: 12px;
        color: #515151;
        text-align: center;
      }
      .inp {
        display: flex;
        align-items: center;
        width: 93%;
        margin: 0 auto;
        height: 41px;
        background: #ffffff;
        border-radius: 5px;
        input {
          margin-left: 4px;
          width: 274px;
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #eeeeee;
        }
      }
  
      .btn2 {
        border: none;
        width: 40px;
        height: 32px;
        background: #d8d8d8;
        border-radius: 4px;
        font-size: 13px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        color: #ffffff;
        margin-left: 4px;
      }
    }
  }
  </style>
  