<template>
  <div class="myConsult">
    <div class="top">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="value1"
          :options="option1"
          @change="selectClassify"
        />
        <van-dropdown-item
          @change="selectStatus"
          v-model="value2"
          :options="option2"
        />
      </van-dropdown-menu>
    </div>
    <div class="middle">
      <!-- 已预约 -->
      <div class="order" v-for="(item, index) in myConsultInfo" :key="index">
        <div class="Otop">
          <div class="banxin m16">
            <p>{{ item.category_name }} - {{ item.service_name }}</p>
            <h2 v-if="item.status == 3">{{ item.status_text }}</h2>
            <h3
              v-else
              style="
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
              "
            >
              {{ item.status_text }}
            </h3>
          </div>
        </div>
        <div class="Omiddle">
          <div class="banxin m17">
            <div class="left">
              <div>
                <span>咨询人员：{{ item.member_name }}</span>
              </div>
              <div>
                <span
                  >预约时间：{{ item.time_date }}
                  <span v-for="(time, index) in item.time_slot" :key="index"
                    >{{ time }}
                  </span></span
                >
              </div>
              <div v-if="item.is_legal_right == 0">
                <span>付款内容：{{ item.pay_money }}</span>
              </div>
              <div v-else><span>付款内容：权益兑换</span></div>
            </div>
            <div class="right">
              <img src="../../img/index/jiantou.png" alt="" />
            </div>
          </div>
        </div>
        <div class="bottom banxin" v-if="item.status == 3">
          <div>
            <button class="cancel" @click="cancelApply(item.id)">
              取消申请
            </button>
            <button class="address" @click="showAddress1(item)">
              咨询地址
            </button>
          </div>
        </div>
        <!-- <div
          class="bottom banxin"
          v-if="item.status == 3 && item.is_legal_right == 1"
        >
          <div>
            <button class="cancel" @click="cancelApply(item.id)">
              取消申请
            </button>
            <button class="address">咨询地址</button>
          </div>
        </div> -->
        <div class="bottom banxin" v-if="item.status == 1">
          <div>
            <!-- <button class="cancel">取消申请</button> -->
            <button class="address" @click="goPay(item.id)">去付款</button>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="showAddress"
      title="咨询地址"
      :showConfirmButton="false"
      :close-on-click-overlay="true"
    >
      <div class="consultBox">
        <div class="m16">邀请您参加企业微信会议</div>
        <div class="m16">
          会议主题：{{ addressInfo.category_name }}-{{
            addressInfo.service_name
          }}
        </div>
        <div class="m16">
          会议时间：{{ addressInfo.time_date }}&nbsp;<span
            v-for="(item, index) in addressInfo.time_slot"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div class="m16">点击链接直接加入会议：</div>
        <div class="m16">{{ addressInfo.meet_address }}</div>
        <!-- <div class="m16">#企业微信会议：822-413-495</div> -->
        <a
          href="javascript:void(0);"
          style="padding-bottom: 10px"
          @click="copy(addressInfo.meet_address)"
          >复制信息</a
        >
      </div>
    </van-dialog>
    <van-dialog
      v-model="showQrcode"
      title="请扫码支付"
      show-cancel-button
      @confirm="queding"
      @cancel="quexiao"
      style="width: 200px; height: 190px"
    >
      <div class="qrcode" ref="qrCodeDiv"></div>
    </van-dialog>
    <!-- <van-dialog v-model="showSucced2" :showConfirmButton="false">
        <div class="order">支付遇到问题？重新支付</div>
        <div class="last">
          <button
            class="left"
            @click="() => this.$router.push('/consult/index')"
          >
            我已支付
          </button>
        </div>
      </van-dialog> -->
    <van-dialog v-model="showSucced" :showConfirmButton="false">
      <div class="order">已预约,等待接受预约!</div>
      <div class="last">
        <button class="left" @click="() => this.$router.push('/consult/index')">
          返回
        </button>
        <!-- <div class="right">查看预约</div> -->
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { consultApi } from "@/api/consult";
import { myConsultApi } from "@/api/myConsult";
import { Dialog } from "vant";
import QRCode from "qrcodejs2";
import { judge } from "../../utils/judge";
import { myRightApi } from "../../api/myRight";
import { convertKey } from "../../utils/index";
import { log } from "console";
export default {
  data() {
    return {
      showSucced2: false,
      showSucced: false,
      order: "",
      timer: "",
      showQrcode: false,
      device: "",
      value1: 0,
      value2: "",
      showAddress: false,
      addressInfo: {},
      option1: [],
      option2: [
        { text: "咨询状态", value: "" },
        { text: "已取消", value: "0" },
        { text: "待付款", value: "1" },
        { text: "已预约", value: "3" },
        { text: "已完成", value: "4" },
        { text: "已退款", value: "5" },
      ],
      myConsultInfo: [],
    };
  },
  created() {
    this.getList();
    this.getClassify();
  },
  methods: {
    quexiao() {
      clearInterval(this.timer);
    },
    queding() {
      clearInterval(this.timer);
    },
    selectClassify() {
      console.log("选择的咨询类型", this.value1);
      this.getList();
    },
    selectStatus() {
      this.getList();
    },
    showAddress1(item) {
      console.log("item", item);
      this.showAddress = true;
      this.addressInfo = item;
    },
    goPay(id) {
      this.device = judge();
      var ua = navigator.userAgent.toLowerCase();
      let isWxWork = ua.match(/WxWork/i) == "wxwork";
      if (!isWxWork) {
        this.$toast("请在企业微信客户端进行支付");
        return;
      }
      console.log("this.device", this.device);
      myRightApi.pay({ id, device: this.device }).then((res) => {
        this.order = res.data.orderNo;
        if (res.code == 0) {
          if (this.device == "m") {
            let url = encodeURIComponent(
              `${
                location.protocol + "//" + location.host
              }/payPopu/index?id=${id}`
            );
            location.href = res.data.wechctUrl + `&redirect_url=${url}`;
          } else {
            this.showQrcode = true;
            this.$nextTick(() => {
              new QRCode(this.$refs.qrCodeDiv, {
                text: res.data.wechctUrl, //url地址  文本等需要转换为二维码的内容
                width: 80,
                height: 80,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
              });
            });
            var that = this;
            this.timer = setInterval(function () {
              console.log("order", that.order);
              consultApi.queryStatus({ orderNo: that.order }).then((res) => {
                console.log("res", res);
                if (res.data == 3) {
                  that.showSucced = true;
                  that.showQrcode = false;
                  clearInterval(that.timer);
                }
                // else {
                //   that.$toast(res.sMsg)
                // }
              });
            }, 1000);
          }
        } else {
          this.$toast(res.sMsg);
        }
      });
    },
    getClassify() {
      consultApi.consultClassify().then((res) => {
        console.log("最开始返回", res.data);
        res.data.unshift({ id: 0, name: "咨询分类" });
        this.option1 = res.data.map((item) => {
          return { value: item.id, text: item.name };
        });
        this.value1 = this.option1[0]?.value;
      });
    },
    getList() {
      myConsultApi
        .myConsult({ serviceId: this.value1, status: this.value2 })
        .then((res) => {
          console.log("我的咨询列表", res);
          this.myConsultInfo = res.data.data;
          this.myConsultInfo.map((item) => {
            if (item.status == 0) {
              return (item.status = "已取消");
            }
          });
          console.log(this.myConsultInfo);
        });
    },
    cancelApply(id) {
      Dialog.confirm({
        message: "确认取消咨询吗？",
      })
        .then(() => {
          myConsultApi.cancelConsult({ id }).then((res) => {
            this.$toast(res.sMsg);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    copy(content) {
      console.log("1111");
      var input = document.createElement("input"); // 创建input对象
      input.value = content; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
      this.showAddress = false;
    },
  },
};
</script>
<style lang="less" scoped>
.qrcode {
  margin: 0 auto;
  width: 80px;
  height: 80px;
}
/deep/ .van-dropdown-item--down {
  margin-top: -17px;
}
/deep/ .van-dialog__message {
  font-size: 16px;
}
@media screen and(min-width: 1024px) {
  .order {
    padding: 4px;
    width: 32% !important;
    margin-left: 1%;
    float: left;
    min-height: 174px !important;
  }
  /deep/ .van-dropdown-item--down{
    overflow: hidden;
    margin-top: 25px !important;
  }
  /deep/ .van-popup--top{
    left: 18% !important;
  }
}
.myConsult {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  .top {
    width: 100%;
    height: 44px;
    overflow: hidden;
  }
}
.Otop {
  height: 36px;
  border-bottom: 1px solid #dbdbdb;
  .m16 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;

    p {
      margin: 0;
      height: 18px;

      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    h2 {
      margin: 0;
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e84a10;
    }
  }
}
.Omiddle {
  min-height: 86px;
  border-bottom: 1px solid #dbdbdb;
  .m17 {
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: 10px;
      height: 78px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
    }
    .right {
      margin-top: 10px;
      height: 78px;
      line-height: 58px;
      img {
        width: 8px;
        height: 8px;
      }
    }
  }
}
.middle {
  .order {
    margin-top: 10px;
    width: 100%;
    min-height: 120px;
    background-color: #fff;
  }
  .bottom {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    .cancel {
      border: none;
      font-size: 13px;
      width: 86px;
      height: 24px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #c1c9d0;
      color: #c1c9d0;
      margin-right: 5px;
    }
    .address {
      border: none;
      width: 86px;
      height: 24px;
      background: #f5a623;
      border-radius: 12px;
      font-size: 13px;
      color: #fff;
    }
  }
}
.consultBox {
  min-height: 198px;
  margin: 0 auto;
  width: 90%;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #999999;
  line-height: 24px;
  .m16 {
    word-break: break-all;
  }
}
/deep/ .van-dialog__header {
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
}
</style>
