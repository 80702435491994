<template>
  <div class="banxin">
    <div style="overflow: hidden" v-if="rightDetailInfo.status==1">
      <h5>已使用咨询服务</h5>
      <p>{{rightDetailInfo.consultServiceCategory.name}}-{{rightDetailInfo.consultService.name}}-30分钟</p>
      <div class="m16">使用时间：{{rightDetailInfo.useTime}}</div>
      <div class="m16">权益咨询服务：已使用</div>
      <div class="m16">可使用时间：{{rightDetailInfo.start_time.slice(0,16)}} - {{rightDetailInfo.end_time.slice(0,16)}}</div>
      <div class="m16">申请员工名称：&nbsp;<ww-open-data
                  type="userName"
                  :openid="rightDetailInfo.employeeName"
              /></div>
    </div>
    <div style="overflow: hidden" v-if="rightDetailInfo.status==0">
      <h5>权益内容</h5>
      <p>{{rightDetailInfo.consultService.name}}-{{rightDetailInfo.consultServiceCategory.name}}-30分钟</p>
      <div class="m16">使用时间：{{rightDetailInfo.start_time.slice(0,16)}} - {{rightDetailInfo.end_time.slice(0,16)}}</div>
      <div class="m16">权益咨询服务：待使用</div>
      <button class="btn" @click="skip">申请使用</button>
    </div>
    <div style="overflow: hidden" v-if="rightDetailInfo.status==-1">
      <h5>权益内容</h5>
      <p>{{rightDetailInfo.consultServiceCategory.name}}-{{rightDetailInfo.consultService.name}}-30分钟</p>
      <div class="m16">使用时间：{{rightDetailInfo.start_time.slice(0,16)}} - {{rightDetailInfo.end_time.slice(0,16)}}</div>
      <div class="m16">权益咨询服务：已过期</div>
      <!-- <button class="btn" @click="skip">申请使用</button> -->
    </div>
  </div>
</template>
<script>
import { myRightApi } from '../../api/myRight'
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      id:'',
      rightDetailInfo:{}
    };
  },
  created() {
    this.getData();
    initAgentConfig();

  },
  methods: {
    getData() {  
      this.id=this.$route.query.id
      console.log('this.id',this.id)
      myRightApi.myRightDetail({id:this.id}).then((res) => {
        console.log("权益详情", res);
        this.rightDetailInfo=res.data
      });
    },
    skip(){
      
      this.$router.push({path:'/applyUse/index',query:{id:this.rightDetailInfo.id,rightName:this.$route.query.rightName}})
    }
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1024px){
  .banxin{
    min-height: 500px !important;
  }
}
h5 {
  margin-top: 12px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}
p {
  height: 19px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 19px;
}
.m16 {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 25px;
}
.btn {
  margin-top: 30px;
  width:100%;
  border: none;
  height: 42px;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}
</style>