var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myMarket"},[_c('div',{staticClass:"top banxin"},[_c('img',{attrs:{"src":require("../../img/market/jingshi.png"),"alt":""}}),_c('van-search',{staticClass:"input",attrs:{"placeholder":"请输入搜索关键词"},on:{"search":_vm.onSearch},model:{value:(_vm.inputSearch),callback:function ($$v) {_vm.inputSearch=$$v},expression:"inputSearch"}}),_c('img',{attrs:{"src":require("../../img/market/add.png"),"alt":""},on:{"click":function () {
          this$1.$router.push('/addChance/index');
        }}})],1),_c('div',{staticClass:"tab"},[_c('van-tabs',{attrs:{"title-active-color":"#3974C6","color":"#3974C6"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"我的商机"}},[_c('div',{staticClass:"banxin",staticStyle:{"margin-top":"-12px"}},[_c('div',{staticClass:"middle"},[_c('div',{staticClass:"Mtop"},[_c('div',[_c('p',[_vm._v("共 "+_vm._s(_vm.chanceList.length)+" 个需求")])]),_c('div',[_c('img',{staticStyle:{"opacity":"0"}})])]),(_vm.chanceList.length > 0)?_c('div',{staticClass:"chance"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.chanceList),function(item,index){return _c('div',{key:index,staticClass:"Cmiddle",on:{"click":function($event){$event.stopPropagation();return _vm.skipMyChanceDetail(
                      item.id,
                      item.audit_status,
                      item.status
                    )}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"ctop"},[_c('h1',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.generalize)+" ")])]),_c('div',{staticClass:"cCenter"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"m17",staticStyle:{"margin-top":"5px"}},[_c('span',[_vm._v("初步预算:")]),_c('i',[_vm._v(_vm._s(item.budget_range_start)+" - "+_vm._s(item.budget_range_end))])]),_c('div',{staticClass:"m17"},[_c('span',[_vm._v("需求时间:")]),_c('span',[_vm._v(_vm._s(item.start_time.slice(0, 16))+" - "+_vm._s(item.end_time.slice(0, 16)))])]),_c('div',{staticClass:"m17"},[_c('span',[_vm._v("区域要求:")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.area))])]),_c('div',{staticClass:"m17"},[_c('em',[_vm._v(_vm._s(item.create_time.slice(0, 16))+"  ")])])])]),(item.audit_status == 2 && item.status == 1)?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bl"},[_c('span',[_vm._v("已抢："+_vm._s(item.grab_num)+"人")])]),_c('button',{staticStyle:{"margin-top":"-4px"},on:{"click":function($event){return _vm.offChance(item.id)}}},[_vm._v(" 下架商机 ")])]):(item.audit_status == 2 && item.status == 0)?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bl"}),_c('button',{staticStyle:{"margin-top":"-4px"}},[_vm._v("已下架")])]):(item.audit_status == 1)?_c('div',{staticClass:"bottom",staticStyle:{"justify-content":"flex-end"}},[_c('button',{staticStyle:{"margin-top":"-4px"}},[_vm._v("待审核")])]):_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bl"},[_c('span',[_vm._v("审核未通过")])]),_c('button',{staticStyle:{"margin-top":"-4px"}},[_vm._v("重新编辑")])])])])}),0)],1):_c('div',[_c('van-empty',{attrs:{"description":"暂无商机"}})],1)])])]),_c('van-tab',{attrs:{"title":"抢商机"}},[_c('myGrapChance')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }