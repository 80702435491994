<template>
  <div class="filter banxin">
    <div class="m16">需求行业</div>
    <van-field
      readonly
      clickable
      :value="classify"
      placeholder="请选择分类"
      @click="showClassify = true"
    />
    <van-popup
      v-model="showClassify"
      :overlay="true"
      style="
        position: absolute;
        top: 167px;
        width: 100%;
        height: auto;
        left: 50% !important;
      "
    >
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
      >
      </van-tree-select>
      <div class="btn" @click="finish">完成</div>
    </van-popup>
    <div class="m16">选择需求区域</div>
    <div class="needTa">
          <multiselect v-model="value.name" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="请选择区域要求" :options="options" :searchable="true" ></multiselect>
        </div>
    <div class="m16 m17">默认排序</div>
    <button
      v-for="(item, index) in selection"
      :key="index"
      @click="order(index)"
      :class="[spanIndex1 == index ? 'btn3' : '']"
    >
      {{ item }}
    </button>
    <div class="last">
      <button class="btn1" @click="() => this.$router.go(-1)">取 消</button>
      <button class="btn2" @click="confirm">确定</button>
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { companyApi } from "../../api/company";
import Multiselect from "vue-multiselect";
import global from "../../components/global"; //引用模块进来

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      spanIndex1: 0,
      options: global.option,
      value: {},
      index2: "",
      classify: "",
      showClassify: false,
      items: [],
      address: "",
      showPicker: false,
      areaList,
      activeId: "",
      activeIndex: 0,
      selection: ["按发布时间最近", "按浏览数"],
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    nameWithLang({ name }) {
      return `${name}`;
    },
    confirm() {
      console.log('区域选择',this.value.name.name)
      this.$router.push({
        path: "/market/index",
        query: {
          category: this.activeId,
          area: this.value.name.name,
          order: this.spanIndex1 + 1,
        },
      });
    },
    // 获取分类列表
    getClassifyList() {
      companyApi.corpClassify().then((res) => {
        console.log("分类", res);
        let option = res.data.map((res) => {
          return {
            text: res.name,
            children: res.childlist,
            id: res.id,
            pid: res.pid,
          };
        });
        console.log("分类1111", option);
        this.items = JSON.parse(
          JSON.stringify(option).replace(/name/g, "text")
        );
        console.log("分类222", this.items);
      });
    },
    //选择分类
    finish() {
      this.showClassify = false;
      console.log("activeIndex", this.activeIndex);
      //子类ID
      console.log("activeId", this.activeId);
      console.log(this.items[this.activeIndex].text);
      this.classify = this.items[this.activeIndex].text;
    },
    order(index) {
      this.spanIndex1 = index;
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .multiselect__tags{
    font-size: 12px;
    border:none;
  }
/deep/ .van-popup {
  left: 0 !important;
}
@media screen and(min-width: 1024px){
  .filter{
    height:500px !important;
  }
}
.filter {
  height: 100%;
  overflow: hidden;
}
.m16 {
  margin-top: 17px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}
.btn {
  width: 30%;
  margin-left: 68%;
  margin-bottom: 6px;
  height: 30px;
  border-radius: 10px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background-color: #0099ff;
}

button {
  border: none;
  width: 121px;
  height: 24px;
  background: #f9f9f9;
  border-radius: 12px;
  font-size: 12px;
  color: #999999;
  margin-right: 20px;
}
.btn3 {
  border: none;
  width: 121px;
  height: 24px;
  background: #f2f8ff;
  border-radius: 12px;
  font-size: 12px;
  color: #3974c6;
  margin-right: 20px;
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 18px;
  width: 100%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>