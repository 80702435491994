<template>
  <div class="qyIndex">
    <div class="top">
      <div class="banxin">
        <div class="bTop">
          <div class="bTopLeft">
            <h4>你好，<ww-open-data type="userName" :openid="info.name" /></h4>
            <span>{{ info.today }}，{{ info.week }}</span>
          </div>
          <!-- <div class="bTopRight">
            <img src="../../img/qyIndex/bell.png" alt="" />
            <span>99+</span>
          </div> -->
        </div>

        <div class="bBottom">
          <div class="content">
            <div class="m16" style="margin-left: -6px">
              <span>企业品牌</span><b>{{ info.brandNum }}</b>
            </div>
            <div class="m16">
              <span>企业员工</span><b>{{ info.employeeNum }}</b>
            </div>
            <div class="m16">
              <span>昨日抢商机</span><b>{{ info.grabNum }}</b>
            </div>
            <div class="m16">
              <span>昨日发布商机</span><b>{{ info.publishNum }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="banxin">
        <div class="mbox">
          <h5>企业信息</h5>
          <div class="box1">
            <div class="left">
              <div
                class="leftTop"
                @click="() => this.$router.push({ path: '/qyChance/index' })"
              >
                &nbsp;&nbsp;<img src="" alt="" />企业商机 >
              </div>
              <div class="leftBott">
                <div class="BB">
                  <span>求购中</span>
                  <p>{{ info.buyNum }}</p>
                </div>
                <div class="BB">
                  <span>总商机</span>
                  <p>{{ info.totalNum }}</p>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rightTop">
                <img src="../../img/index/1.jpg" alt="" />
                <span>&nbsp;&nbsp;&nbsp;{{info.brandNum}}个企业品牌</span>
              </div>
              <div
                class="rightBot"
                @click="
                  () => {
                    this.$router.push({ path: '/qyBrandManage/index' });
                  }
                "
              >
                <a href="">管理企业品牌 ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="mbox" style="height: 158px">
          <h5>营销工具</h5>
          <div class="box2">
            <div class="m17">
              <div><img style="width:29px;height: 29px;" src="../../img/qyIndex/pingjia.png" alt="" /></div>
              <div class="m">企业评价</div>
            </div>
            <div class="m17">
              <div><img style="width:29px;height: 29px;" src="../../img/qyIndex/shangxiayou.png" alt="" /></div>
              <div class="m">上下游</div>
            </div>
            <div class="m17">
              <div><img style="width:29px;height: 29px;" src="../../img/qyIndex/qunfa.png" alt="" /></div>
              <div class="m">上下游群发</div>
            </div>
            <div class="m17">
              <div><img style="width:29px;height: 29px;" src="../../img/qyIndex/qypeizhi.png" alt="" /></div>
              <div class="m">企业配置</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="ft"></Footer>
  </div>
</template>
<script>
import { qyIndexApi } from "../../api/qyIndex";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    initAgentConfig();
    setTimeout(() => {
      this.getData();
    },300)
    sessionStorage.setItem("active", 4);
  },
  methods: {
    getData() {
      qyIndexApi.homepage().then((res) => {
        console.log("管理端首页数据", res);
        this.info = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.qyIndex {
}
@media screen and(min-width: 1024px) {
  .ft{
  display: none;
}
}

.banxin {
  width: 94%;
  margin: 0 auto;
}
.top {
  overflow: hidden;
  width: 100%;
  height: 186px;
  background: linear-gradient(180deg, #3974c7 0%, #0c43c3 100%);
}
.bTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  height: 62px;
  .bTopLeft {
    margin-left: 8px;
    width: 144px;
    height: 60px;
    h4 {
      margin: 0;
      height: 34px;
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 34px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #a6cdfe;
    }
  }
  .bTopRight {
    position: relative;
    margin-right: 8px;
    width: 40px;
    height: 40px;

    span {
      top: 0;
      right: -14px;
      padding: 2px;
      position: absolute;
      font-size: 10px;
      color: #fff;
      text-align: center;
      line-height: 13px;
      background: #ff5e43;
      border-radius: 7px;
      border: 1px solid #ffffff;
    }
  }
}
.bBottom {
  margin-top: 26px;
  height: 88px;
  background: #3478ec;
  border-radius: 18px 18px 0px 0px;
  border: 1px solid #a9d1fd;
  .content {
    display: flex;
    width: 94%;
    margin: 0 auto;
    .m16 {
      margin-top: 14px;
      width: 25%;
      text-align: center;
      display: flex;
      flex-direction: column;

      span {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #a6cdfe;
        line-height: 20px;
      }
      b {
        height: 34px;
        font-size: 24px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
.middle {
  margin-top: -2px;
  height: 548px;
  background: #f2f3f7;
  border-radius: 4px;
  .mbox {
    height: 173px;
    h5 {
      margin: 0;
      height: 60px;
      font-size: 19px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #262626;
      line-height: 60px;
    }
    .box1 {
      display: flex;
      justify-content: space-between;
      .left {
        width: 48%;
        height: 113px;
        background: #dde8ff;
        border-radius: 12px;
        .leftTop {
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #4a4a4a;
        }
        .leftBott {
          display: flex;

          margin: 0 auto;
          width: 96%;
          height: 68px;
          background: #ffffff;
          border-radius: 8px;
          .BB {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 12px;
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #717174;
            }
            p {
              font-size: 19px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #262626;
            }
          }
        }
      }
      .right {
        width: 48%;
        height: 113px;
        background: #ffffff;
        border-radius: 12px;
        .rightTop {
          height: 40px;
          width: 90%;
          margin: 0 auto;
          margin-top: 14px;
          margin-bottom: 20px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #4a4a4a;
          img {
            width: 40px;
            height: 40px;
          }
        }

        .rightBot {
          width: 90%;
          margin: 0 auto;
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #3974c6;
        }
      }
    }
  }
}
.box2 {
  display: flex;
  height: 98px;
  background: #ffffff;
  border-radius: 10px;
  .m17 {
    height: 90px;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #4a4a4a;
    }
    .m {
      margin-top: 10px;
    }
  }
}
</style>