<template>
    <div class="my">
      <div class="top">
        <div class="content banxin">
          <div class="m16">
            <div class="left">
              <p>{{ userInfo.corpName }} ></p>
              <h3>
                <ww-open-data
                  type="userName"
                  :openid="userInfo.name"
                  :corpid="userInfo.corp_id"
                />
              </h3>
            </div>
            <div class="right">
              <img :src="userInfo.avatar" alt="" />
            </div>
          </div>
          <div class="warm m16">
            温馨提示：合作时请按照企业正规流程操作，请勿随意打款转账等。
          </div>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="middle">
        <div class="Mtop banxin">
          <div class="m12">
            <p>申请入驻</p>
            <span>&gt;</span>
          </div>
          <div class="m12" @click="sao">
            <p>扫码领取企业权益红包</p>
            <span>&gt;</span>
          </div>
        </div>
        <div class="Mmiddle banxin">
          <div
            class="m12"
            @click="
              () => {
                this.$router.push({ path: '/myMarket/index' });
              }
            "
          >
            <p>我的市场</p>
            <span>&gt;</span>
          </div>
          <div
            class="m12"
            @click="
              () => {
                this.$router.push({ path: '/myConsult/index' });
              }
            "
          >
            <p>我的咨询</p>
            <span>&gt;</span>
          </div>
          <div
            class="m12"
            @click="
              () => {
                this.$router.push({ path: '/myRight/index' });
              }
            "
          >
            <p>我的权益</p>
            <span>&gt;</span>
          </div>
        </div>
        <div class="Mbottom banxin">
          <div
            class="m12"
            @click="
              () => {
                this.$router.push({ path: '/helpCenter/index' });
              }
            "
          >
            <p>帮助中心</p>
            <span>&gt;</span>
          </div>
          <div
            class="m12"
            @click="
              () => {
                this.$router.push({ path: '/feedBack/index' });
              }
            "
          >
            <p>意见反馈</p>
            <span>&gt;</span>
          </div>
        </div>
        <div class="last2">
          <p>深圳市启晟教育科技有限公司</p>
          <p>0755-1242434343</p>
          <span>联系客服</span>
        </div>
      </div>
      <div style="padding-bottom: 20px; background-color: #f2f3f7"></div>
    </div>
  </template>
  <script>
  import { myApi } from "../../api/my";
  import { judge } from "../../utils/judge";
  import { scanQRCode, initAgentConfig } from "../../utils/wxCodeAuth";
  
  export default {
    data() {
      return {
        userInfo: "",
      };
    },
    created() {
      sessionStorage.setItem("active", 3);
      initAgentConfig();
      setTimeout(() => {
      this.getUserInfo();
      },300)
    },
    methods: {
      sao() {
        if (this.device == "pc") {
          this.$toast("请在移动端操作扫码领取权益！");
        } else {
          var skip = (res) => {
            this.$router.push({ path: "/saoyisao/detail", query: { id: res } });
          };
          scanQRCode(skip);
        }
      },
      getUserInfo() {
        this.device = judge();
        myApi.myInfo().then((res) => {
          this.userInfo = res.data;
          console.log(res);
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
      .banxin {
  width: 94%;
  margin: 0 auto;
  // height: 100%;
}
  .my {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .top {
    overflow: hidden;
    width: 100%;
    height: 140px;
    background: linear-gradient(180deg, #3974c7 0%, #0c43c3 100%);
    .content {
      margin-top: 16px;
      height: 124px;
      background: #3478ec;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #3478ec;
      .m16 {
        display: flex;
        justify-content: space-between;
        width: 98%;
        margin: 10px auto;
        height: 60px;
        .left {
          p {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
          h3 {
            height: 25px;
            font-size: 18px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 25px;
          }
        }
        .right {
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  .warm {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  // 中间部分
  .middle {
    overflow: hidden;
    margin-top: -6px;
    // width: 100%;
    // height: 496px;
    flex: 1;
    overflow: auto;
    background: #f2f3f7;
    border-radius: 4px;
    .Mtop {
      margin-top: 16px;
      // width: 100%;
      height: 100px;
      background: #ffffff;
      border-radius: 5px;
    }
    .m12 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 93%;
      height: 50px;
      margin: 0 auto;
      border-bottom: 1px solid #e6e6e6;
      p {
        margin: 0;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
      span {
        font-size: 18px;
        display: inline-block;
        line-height: 6px;
        width: 5px;
        height: 7px;
        color: #adadb0;
        margin-right: 6px;
      }
    }
    .Mmiddle {
      margin-top: 10px;
      height: 150px;
      background: #ffffff;
      border-radius: 5px;
    }
    .Mbottom {
      margin-top: 10px;
      height: 100px;
      background: #ffffff;
      border-radius: 5px;
    }
    .last2 {
      display: flex;
      flex-direction: column;
      height: 50px;
      margin-top: 10px;
      text-align: center;
      p {
        width: 100%;
        margin: 0;
        height: 18px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      span {
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3974c6;
      }
    }
  }
  </style>
  