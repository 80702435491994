<template>
  <div class="qyAddBrand" style="background: #fff">
    <div class="banxin">
      <van-cell-group>
        <van-field
          class="m16"
          v-model="qyName"
          required
          label="企业名称"
          placeholder="请输入企业名称"
          input-align="right"
          readonly
        />
        <van-field
          readonly
          class="m16"
          v-model="qyClassify"
          required
          label="品牌分类"
          placeholder="请选择品牌分类"
          input-align="right"
          @click="showClassify = true"
        />
        <van-popup
          v-model="showClassify"
          :overlay="true"
          style="
            position: absolute;
            top: 167px;
            width: 106%;
            height: auto;
            left: 50% !important;
          "
        >
          <van-tree-select
            :items="items"
            :active-id.sync="activeId"
            :main-active-index.sync="activeIndex"
          >
          </van-tree-select>
          <div class="btn" @click="finish">完成</div>
        </van-popup>
        <van-field
          class="m16"
          v-model="brandName"
          required
          label="品牌名称"
          placeholder="请输入品牌名称"
          input-align="right"
        />
        <van-field
          class="m16"
          v-model="official_website"
          required
          label="企业官网"
          placeholder="请输入企业官网"
          input-align="right"
        />
        <div class="logo">
          <span><i style="color: red">*</i>品牌logo</span>
          <van-uploader
            v-model="upfileList"
            :max-count="1"
            :after-read="afterRead"
          />
        </div>
        <van-field
          class="m16"
          v-model="liveCodeName"
          required
          label="活码名称"
          placeholder="请输入活码名称"
          input-align="right"
        />
        <div class="need" style="height: 56px">
          <div class="needT"><i style="color: red">*</i>状态</div>
          <van-radio-group class="m16" v-model="radio" direction="horizontal">
            <van-radio name="1">启用</van-radio>
            <van-radio name="0">禁用</van-radio>
          </van-radio-group>
        </div>
        <div class="need">
          <div class="needT"><i style="color: red">*</i>时间</div>
          <div class="list-item">
            <div class="item-content">
              <div
                @click="$refs.starttime.$show()"
                style="flex: 1; text-align: center"
              >
                {{ sTime ? sTime : "开始时间" }}
              </div>
              -
              <div
                @click="$refs.endtime.$show()"
                style="flex: 1; text-align: center"
              >
                {{ endTime ? endTime : "结束时间" }}
              </div>
            </div>
          </div>
        </div>
        <!-- <van-field
          class="m16"
          v-model="member"
          placeholder="请选择成员，可多选"
          input-align="right"
        /> -->
        <div class="needT"><i style="color: red">*</i>选择员工</div>
        <van-search
          v-model="searchName"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
        </van-search>
        <i class="selectName" v-if="selectMember!=''"><ww-open-data
                  type="userName"
                  :openid="selectMember"
              /></i>
        <div class="userName" v-for="(item,index) in nameInfo" :key="index"  @click="selectMem(item)"><ww-open-data
                  type="userName"
                  :openid="item"
              /></div>
              <!-- <div class="userName" v-for="(item,index) in options" :key="index" @click="selectMem(item.name)">{{item.name}}</div> -->
        <van-field
          input-align="right"
          required
          readonly
          clickable
          label="地址"
          :value="value"
          placeholder="选择地址"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-area
            :area-list="areaList"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
        <van-field
          class="m16"
          v-model="DetailAddress"
          placeholder="详细地址"
          input-align="right"
        />
        <van-field
          class="m16"
          v-model="range"
          rows="2"
          autosize
          label="经营范围"
          type="textarea"
          maxlength="50"
          show-word-limit
          required
        />
        <van-field
          class="m16"
          v-model="briefIntro"
          rows="2"
          autosize
          label="简介"
          type="textarea"
          maxlength="50"
          show-word-limit
          required
        />
        <van-uploader
          style="margin-left: 80%"
          v-model="upfileList2"
          :max-count="1"
          :after-read="afterRead2"
        />
      </van-cell-group>
      <div style="padding-bottom: 50px"></div>
      <div class="last">
        <van-button
          class="btn1"
          @click="
            () => {
              this.$router.go(-1);
            }
          "
          >返回</van-button
        >
        <van-button v-if="this.$route.query.id" class="btn2" @click="edit"
          >编辑</van-button
        >
        <van-button v-else class="btn2" @click="handAddBrand">确定</van-button>
      </div>
    </div>
    <ComaddTime ref="starttime" @change="choseStartTime" />
    <ComaddTime ref="endtime" @change="choseEndTime" />
  </div>
</template>
<script>
import { qyIndexApi } from "../../api/qyIndex";
import Multiselect from "vue-multiselect";
import { areaList } from "@vant/area-data";
import { companyApi } from "../../api/company";
import { brandApi } from "../../api/brand";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectMember:'',
      nameInfo:[],
      showClassify: false,
      activeId: "",
      activeIndex: 0,
      items: [],
      searchName: "",
      areaList,
      multiValue: "",
      province: "",
      city: "",
      options: [
        { name: "张三", id: 1 },
        { name: "张三1", id: 2 },
        { name: "李四", id: 3 },
        { name: "王五", id: 4 },
      ],
      radio: "1",
      sTime: "",
      endTime: "",
      qyName: "",
      qyClassify: "",
      brandName: "",
      official_website: "",
      logo: "",
      liveCodeName: "",
      member: "",
      address: "",
      DetailAddress: "",
      range: "",
      briefIntro: "",
      upfileList: [],
      upfileList2: [],
      value: "",
      showPicker: false,
    };
  },
  created() {
    initAgentConfig();
    this.getClassifyList();
    this.qyName = this.$route.query.name;
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    selectMem(name){
      console.log('name',name)
      this.selectMember=name
      console.log(this.selectMember)
    },
    onSearch() {
      qyIndexApi.searchEm({ queryWord: this.searchName }).then((res) => {
        console.log("搜索员工", res);
        this.nameInfo=res.data
      });
    },
    getDetail() {
      qyIndexApi.brandDetail({ id: this.$route.query.id }).then((res) => {
        console.log("品牌详情", res);
        this.upfileList[0]={}
        this.upfileList2[0]={}
        this.brandName = res.data.name;
        // this.qyClassify=res.data.category_id
        this.official_website = res.data.official_website;
        this.upfileList[0].url = res.data.logo_show;
        this.upfileList2[0].url = res.data.introduction_img_show;
        this.upfileList[0].path=res.data.logo
        this.upfileList2[0].path=res.data.introduction_img
        this.liveCodeName = res.data.live_code_name;
        this.radio = res.data.status.toString();
        this.DetailAddress = res.data.address;
        this.range = res.data.business_scope;
        this.briefIntro = res.data.introduction;
        //地址
        this.value = res.data.province + res.data.city + res.data.district;
        this.province=res.data.province,
        this.city=res.data.city,
        this.district=res.data.district,
        //开始时间
        this.sTime=res.data.start_time
        this.endTime=res.data.end_time
        this.selectMember=res.data.wx_user_id
      });
    },
    // 获取分类列表
    getClassifyList() {
      brandApi.classifyList().then((res) => {
        console.log("分类", res);
        this.items = res.data;
      });
    },
    //选择分类
    finish() {
      this.showClassify = false;
      console.log("activeIndex", this.activeIndex);
      //子类ID
      console.log("activeId", this.activeId);
      console.log(this.items[this.activeIndex].text);
      this.qyClassify = this.items[this.activeIndex].text;
    },
    edit() {
      let params = {
        id: this.$route.query.id,
        category_id: this.activeId,
        name: this.brandName,
        official_website: this.official_website,
        logo: this.upfileList[0].path,
        live_code_name: this.liveCodeName,
        start_time: this.sTime,
        end_time: this.endTime,
        status: this.radio,
        live_employee_id: this.selectMember,
        province: this.province,
        city: this.city,
        district: this.district,
        address: this.DetailAddress,
        business_scope: this.range,
        introduction: this.briefIntro,
        introduction_img: this.upfileList2[0].path,
      };
      qyIndexApi.editBrand(params).then(res=>{
        if (res.code == 0) {
          this.$toast("编辑成功");
          this.$router.push('/qyBrandManage/index')
        } else {
          this.$toast(res.sMsg);
        }
      })
    },
    nameWithLang({ name, language }) {
      return `${name}`;
    },
    choseStartTime(data) {
      console.log("返回的时间", data);
      this.sTime = data;
    },
    choseEndTime(data) {
      console.log("返回的时间", data);
      this.endTime = data;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      qyIndexApi.uploadFile(formData).then((res) => {
        console.log(res)
        console.log("上传成功");
        this.upfileList[0].path = res.data.src;
        this.upfileList[0].url = res.data.src_show

      });
      console.log(this.upfileList);
    },
    afterRead2(file) {
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      qyIndexApi.uploadFile(formData).then((res) => {
        console.log("上传成功");
        this.upfileList2[0].path = res.data.src;
        this.upfileList2[0].url = res.data.src_show

      });
      console.log(this.upfileList2);
    },
    onConfirm(item) {
      console.log("选择的地址", item);
      // this.value = value;
      this.value = item[0].name + item[1].name + item[2].name;
      this.province = item[0].name;
      this.city = item[1].name;
      this.district = item[2].name;
      this.showPicker = false;
    },
    handAddBrand() {
      let params = {
        category_id: this.activeId,
        name: this.brandName,
        official_website: this.official_website,
        logo: this.upfileList[0].path,
        live_code_name: this.liveCodeName,
        start_time: this.sTime,
        end_time: this.endTime,
        status: this.radio,
        live_employee_id: this.selectMember,
        province: this.province,
        city: this.city,
        district: this.district,
        address: this.DetailAddress,
        business_scope: this.range,
        introduction: this.briefIntro,
        introduction_img: this.upfileList2[0].path,
      };
      qyIndexApi.addBrand(params).then((res) => {
        if (res.code == 0) {
          this.$toast("添加成功");
          this.$router.push('/qyBrandManage/index')
        } else {
          this.$toast(res.sMsg);
        }
        console.log("添加品牌", res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .van-search{
    display: inline-block;
  }
  @media screen and (min-width: 1024px){
    /deep/ .van-popup--bottom{
      left: 18% !important;
    }
  }
  .selectName{
    padding: 5px 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    background-color: blue;
    border-radius: 8px;
    color:#fff;
  }
  .userName{
    color: #646566;
    font-size: 14px;
    margin-left:14px ;
    height: 22px;
    line-height: 20px;
    
  }
  .van-search{
    width: 75%;
  }
.needT {
  height: 32px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #646566;
  line-height: 32px;
  padding-left: 10px;
}
// 时间选择
.list-item {
  margin-bottom: 10px;
}
.item-content {
  width: 100%;
  /* background: red; */
  border: 1px solid #e5e6e7;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #646566;
}
.m16 {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
}
.logo {
  height: 60px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  span {
    height: 18px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #646566;
    line-height: 21px;
  }
}
.btn {
  width: 30%;
  margin-left: 68%;
  margin-bottom: 6px;
  height: 30px;
  border-radius: 10px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background-color: #0099ff;
}
.last {
  width: 92%;
  display: flex;
  position: fixed;
  bottom: 5px;
  justify-content: space-between;
  .btn1 {
    width: 46%;
    background-color: #fff;
    border-radius: 5px;
    color: #c1c9d0;
  }
  .btn2 {
    width: 46%;
    background-color: #3974c6;
    border-radius: 5px;
    color: #fff;
  }
}
/deep/ .van-uploader__upload {
  width: 60px;
  height: 60px;
}
/deep/ .van-uploader__preview-image {
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 1024px){
/deep/ .van-popup--bottom {
  left: 0 !important;
}
}
</style>