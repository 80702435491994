<template>
  <div class="seizeChance">
    <div class="banxin">
      <!-- 发布的商机 -->
      <div class="chance">
        <div class="Cmiddle">
          <div class="content">
            <div class="ctop">
              <h1>{{ chanceDetailInfo.name }}</h1>
              <p>
                {{ chanceDetailInfo.generalize }}
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>初步预算: </span>
                  <i
                    >{{ chanceDetailInfo.budget_range_start }} -
                    {{ chanceDetailInfo.budget_range_end }}</i
                  >
                </div>
                <div class="m17">
                  <span>需求时间: </span>
                  <span
                    >{{ chanceDetailInfo.start_time }} -
                    {{ chanceDetailInfo.end_time }}</span
                  >
                </div>
                <div class="m17">
                  <span>区域要求: </span>
                  <span>{{ chanceDetailInfo.area }}</span>
                </div>
                <div class="m17">
                  <img :src="chanceDetailInfo.corp_logo" alt="" /><em>{{
                    chanceDetailInfo.corp_name
                  }}</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 需求方信息 -->
      <div class="info">
        <div class="need">需求方信息</div>
        <div class="bt">
          <div class="m18">
            <span>发布人所在地址: </span>
            <span
              >{{ chanceDetailInfo.province }}{{ chanceDetailInfo.city
              }}{{ chanceDetailInfo.district }}</span
            >
          </div>
          <div class="m18">
            <span>发布时间: </span>
            <span>{{ chanceDetailInfo.create_time }}</span>
          </div>
        </div>
      </div>
      <!-- 平台筛选  -->
      <div class="info">
        <div class="need">
          平台筛选 <em>(筛查内容为平台对该商机的回访结果，仅供参考)</em>
        </div>
        <div class="bt">
          <div class="m18">
            <span>回放结果: </span>
            <span>{{ chanceDetailInfo.audit_result }}</span>
          </div>
          <div class="m18">
            <span>回访需求: </span>
            <span>{{
              chanceDetailInfo.audit_remark
                ? chanceDetailInfo.audit_remark
                : "无"
            }}</span>
          </div>
        </div>
      </div>
      <!-- 平台声明 -->
      <div class="claim">
        平台声明<br />
        本平台仅为企业之间真是商机信息流转平台，实现全国企业之间资源有效共享。请勿发布虚假信息；本平台不收取任何代收费用，企业间合作请参照企业合作合法流程操作，谨慎操作打款转账交易。
      </div>
      <div class="last">
        <button class="btn1">转发商机</button>
        <button
          class="btn2"
          @click="
            () => {
              this.$router.push({
                path: '/grapMessage/index',
                query: { id: chanceDetailInfo.id },
              });
            }
          "
        >
          抢商机
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { chanceApi } from "../../api/chance";
export default {
  data() {
    return {
      chanceDetailInfo: {},
    };
  },
  created() {
    this.getChanceDetail();
  },
  methods: {
    getChanceDetail() {
      let id1 = location.search.split("=");
      let id = id1[1];
      chanceApi.chanceDetail({ id }).then((res) => {
        console.log("商机详情", res);
        this.chanceDetailInfo = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.banxin {
  overflow: hidden;
}
// 发布的商机
.chance {
  margin-top: 10px;
  min-height: 198px;
  .Cmiddle {
    width: 100%;
    min-height: 198px;
    background: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    .content {
      margin: 12px auto;
      width: 94%;
      .ctop {
        width: 100%;
        min-height: 56px;
        h1 {
          width: 100%;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          width: 100%;
          min-height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
        }
      }
      .cCenter {
        width: 100%;
        min-height: 107px;
        background: #ffffff;
        border-radius: 5px;
        overflow: hidden;
        .box {
          width: 97%;
          margin: 8px auto;
          margin-top: 6px;
          .m17 {
            min-height: 20px;
            line-height: 19px;
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
  }
}
.info {
  margin-top: 10px;
  min-height: 98px;
  background: #f9f9f9;
  border-radius: 5px;
  .need {
    padding-left: 15px;
    height: 34px;
    line-height: 34px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 13px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #4a4a4a;
    em {
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;
    }
  }
  .bt {
    margin-left: 15px;
    .m18 {
      min-height: 24px;
      line-height: 20px;
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #4a4a4a;
      }
    }
  }
}
// 平台声明
.claim {
  margin-top: 10px;
  height: 93px;
  font-size: 12px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #999999;
  line-height: 19px;
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 8px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>