<template>
    <div class="search" style="background: #fff">
      <div class="top">
        <img src="../../img/index/fenlei.png" alt="" />
        <van-search
          class="input"
          v-model="inputSearch"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        />
      </div>
      <!-- <div> -->
      <div class="none banxin" v-if="flag1 == false && flag2 == false">
        <p>暂无关于“{{ inputSearch }}”的搜索内容</p>
      </div>
      <div class="inter banxin" v-if="flag1 == false">猜您感兴趣的企业品牌</div>
  
      <!-- 企业品牌 -->
      <div class="company banxin">
        <div class="Stop">
          <div class="left">企业品牌</div>
        </div>
        <div v-if="companyBrandInfo.length > 0">
          <div
            class="Smiddle"
            v-for="(item, index) in companyBrandInfo"
            :key="index"
            @click="skipBrandDetail(item.id)"
          >
            <div class="you" v-if="item.recommend != ''">
              {{ item.recommend }}
            </div>
            <div class="left">
              <img :src="item.logo" alt="" v-if="item.logo" />
              <span v-else>{{ item.corp_name.slice(0, 1) }}</span>
            </div>
            <div class="right">
              <h1>{{ item.name }}</h1>
              <p>{{ item.introduction }}</p>
              <div class="bottom">
                <div class="bleft">
                  <img src="" /><span>
                    {{ item.corp_name }}
                  </span>
                </div>
                <!-- <div class="p">100+人最近联系</div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无品牌信息~" style="padding: 0" />
        </div>
      </div>
      <!--企业商机 -->
      <div class="chance banxin">
        <div class="inter banxin" v-if="flag2 == false">猜您感兴趣的企业商机</div>
        <div class="Ctop">
          <div class="left">企业商机</div>
          <!-- <div class="right">
                <button>本周内</button>
                <button style="border-left: ">本月内</button>
              </div> -->
        </div>
        <div v-if="companyChanceInfo.length > 0">
          <div
            class="Cmiddle"
            v-for="(item, index) in companyChanceInfo"
            :key="index"
            @click="skipChanceDetail(item.id)"
          >
            <div class="content">
              <div class="ctop">
                <h1>{{ item.name }}</h1>
                <p>
                  {{ item.generalize }}
                </p>
              </div>
              <div class="cCenter">
                <div class="box">
                  <div class="m17">
                    <span>初步预算:</span>
                    <i
                      >{{ item.budget_range_start }} -
                      {{ item.budget_range_end }}</i
                    >
                  </div>
                  <div class="m17">
                    <span>需求时间:</span>
                    <span
                      >{{ item.start_time.slice(0, 16) }} -
                      {{ item.end_time.slice(0, 16) }}</span
                    >
                  </div>
                  <div class="m17">
                    <span>区域要求:</span> <span>{{ item.area }}</span>
                  </div>
                  <div class="m17">
                    <img :src="item.corp_logo" alt="" /><em>{{
                      item.corp_name
                    }}</em>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="bl">
                  <span>已抢：{{ item.grap_num ? item.grap_num : 0 }}人</span>
                </div>
                <button style="margin-top: -4px" @click.stop="skipGrap(item.id)">
                  抢商机
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无商机~" style="padding: 0" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import { brandApi } from "../../api/brand";
  import { chanceApi } from "../../api/chance";
  export default {
    data() {
      return {
        inputSearch: "",
        queryWord: "",
        companyBrandInfo: [],
        companyChanceInfo: [],
        flag1: true,
        flag2: true,
      };
    },
    watch: {
      inputSearch(newValue) {
        let query = this.inputSearch;
        let path = this.$router.history.current.path;
        this.$router.push({ path, query: { search: query } });
      },
    },
    mounted() {
      this.queryWord = this.$route.query.search;
      this.inputSearch = this.$route.query.search;
      this.getBrandList();
      this.getChanceList();
    },
    methods: {
      skipGrap(id) {
        this.$router.push({ path: "/grapMessage/index", query: { id } });
        return;
      },
      skipChanceDetail(id) {
        this.$router.push({ path: "/chanceDetail/index", query: { id: id } });
      },
      onSearch() {
        this.queryWord = this.inputSearch;
        this.getBrandList();
        this.getChanceList();
      },
      // 获取品牌数据
      getBrandList() {
        brandApi.brandList({ queryWord: this.queryWord }).then((res) => {
          console.log("品牌列表", res);
          if (res.data.data.length > 0) {
            this.companyBrandInfo = res.data.data;
          } else {
            this.flag1 = false;
            brandApi.interestBrand().then((res) => {
              console.log("感兴趣的品牌", res);
              this.companyBrandInfo = res.data;
            });
          }
        });
      },
      skipBrandDetail(id) {
        this.$router.push({ path: "/brandInfo/index", query: { id } });
      },
      getChanceList() {
        chanceApi.chanceList({ queryWord: this.queryWord }).then((res) => {
          console.log("企业商机", res);
          if (res.data.data.length > 0) {
            this.companyChanceInfo = res.data.data;
          } else {
            this.flag2 = false;
            chanceApi.interestList().then((res) => {
              console.log("感兴趣的商机", res);
              this.companyChanceInfo = res.data;
            });
          }
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .search {
    min-height: 100%;
  }
  // 顶部部分
  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin-top: 10px;
      width: 24px;
      height: 18px;
    }
    .input {
      margin-top: 10px;
      border: none;
      background: #f9f9f9;
      width: 86%;
      height: 36px;
      border-radius: 20px;
      font-size: 12px;
    }
  }
  .none {
    width: 100%;
    text-align: center;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #d8d8d8;
    p {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #4a4a4a;
    }
  }
  .inter {
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4a4a4a;
  }
  .Stop {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3da1fe;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }
  // 企业品牌部分
  .company {
    min-height: 323px;
    overflow: hidden;
    .you {
      position: absolute;
      top: 10px;
      right: 0;
      text-align: center;
      color: #fff;
      font-size: 12px;
      width: 59px;
      height: 18px;
      background: #f5a623;
      border-radius: 50px 0px 0px 50px;
    }
    .Smiddle {
        float: left;
        width: 48%;
        margin-right: 2%;
        min-height: 93px;
        overflow: hidden;
        background: #f2f8ff;
        border-radius: 5px;
        margin-bottom: 8px;
      .left {
        float: left;
        width: 40px;
        margin: 14px 4px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        span {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          color: #fff;
          background-color: #3974c6;
          text-align: center;
        }
      }
      .right {
        float: left;
        // width: 296px;
        width: 84%;
        margin-top: 14px;
        h1 {
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          // width: 286px;
          width: 100%;
          min-height: 20px;
          margin: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8f8f8f;
          line-height: 19px;
          text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          height: 20px;
          line-height: 20px;
          margin-bottom: 10px;
          .bleft {
            margin-top: -4px;
            height: 20px;
            line-height: 20px;
            span {
              display: inline-block;
              margin-top: -4px;
              height: 20px;
              line-height: 20px;
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
          .p {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8f8f8f;
          }
        }
      }
    }
  }
  // 发布的商机
  .chance {
    min-height: 200px;
    .Ctop {
      min-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 21px;
        padding-left: 8px;
        border-left: 2px solid #3974c6;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #000000;
        line-height: 21px;
      }
      .right {
        button {
          width: 54px;
          height: 20px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #3974c7;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3974c6;
          line-height: 15px;
        }
      }
    }
    .Cmiddle {
        float: left;
      width:48%;
      margin-top: 10px;
      margin-right: 2%;
      min-height: 210px;
      background: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
      .content {
        margin: 9px auto;
        width: 94%;
        .ctop {
          width: 100%;
          min-height: 50px;
          h1 {
            width: 100%;
            height: 21px;
            font-size: 15px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #4a4a4a;
            line-height: 21px;
          }
          p {
            width: 100%;
            min-height: 20px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
             /*1. 先强制一行内显示文本*/
          white-space: nowrap;
          /*2. 超出的部分隐藏*/
          overflow: hidden;
          /*3. 文字用省略号替代超出的部分*/
          text-overflow: ellipsis;
          }
        }
        .cCenter {
          width: 100%;
          min-height: 107px;
          background: #ffffff;
          border-radius: 5px;
          .box {
            width: 97%;
            margin: 8px auto;
            .m17 {
              height: 20px;
            }
            span {
              height: 19px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
              line-height: 19px;
            }
            i {
              height: 20px;
              font-size: 14px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #e84a10;
              line-height: 20px;
            }
            em {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
        }
      }
      .bottom {
        display: flex;
        height: 28px;
        justify-content: space-between;
        align-items: center;
        line-height: 28px;
        margin-top: 8px;
        .bl {
          font-size: 13px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e84a10;
          line-height: 20px;
        }
        button {
          border: none;
          font-size: 14px;
          color: #fff;
          width: 86px;
          height: 24px;
          line-height: 20px;
          background: #f5a623;
          border-radius: 12px;
        }
      }
    }
  }
  </style>