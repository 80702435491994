<template>
  <div class="selectConsult">
    <div class="banxin" style="overflow: hidden">
      <div class="section">
        <span>咨询分类</span>
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="options" />
        </van-dropdown-menu>
      </div>
      <div class="section" @click="skip">
        <span>选择成员</span>
        <div class="member">{{ nickName }}</div>
      </div>
      <div class="date">
        <van-cell
          title="选择日期"
          :value="form.YTimeDate"
          @click="show = true"
        />
        <van-calendar v-model="show" @confirm="onConfirm" color="#1989fa" />
      </div>
      <div class="time">
        <div class="m16" v-show="memberMorDate.length > 0">
          <p>上午</p>
          <div
            style="display: inline-block; width: 33.3%"
            v-for="(item, index) in memberMorDate"
            :key="index"
          >
            <button
              v-if="item.status == 0"
              @click="getMorTime(index)"
              :class="{ active: spanIndex1.indexOf(index) > -1 }"
            >
              {{ item.time_slot }}
            </button>
            <button class="gg" v-else disabled>
              {{ item.time_slot }}
            </button>
          </div>
        </div>
        <div class="m16" v-show="memberAftDate.length > 0">
          <p>下午</p>
          <div
            style="display: inline-block; width: 33.3%; font-size: 14px"
            v-for="(item, index) in memberAftDate"
            :key="index"
          >
            <button
              v-if="item.status == 0"
              @click="getAftTime(index)"
              :class="{ active: spanIndex2.indexOf(index) > -1 }"
            >
              {{ item.time_slot }}
            </button>
            <button class="gg" v-else disabled>
              {{ item.time_slot }}
            </button>
          </div>
        </div>
      </div>
      <div class="pay">
        <div class="payLeft">付款金额:</div>
        <div class="payRight">{{ allcost }}元</div>
      </div>
      <div class="bottom"><button @click="confirmPay">确定并付款</button></div>
      <van-dialog
        v-model="showQrcode"
        title="请扫码支付"
        show-cancel-button
        @confirm="queding"
        @cancel="quexiao"
      >
        <div class="qrcode" ref="qrCodeDiv"></div>
      </van-dialog>
      <van-dialog v-model="showSucced" :showConfirmButton="false">
        <div class="order">已预约,等待接受预约!</div>
        <div class="last">
          <button
            class="left"
            @click="() => this.$router.push('/consult/index')"
          >
            返回
          </button>
          <!-- <div class="right">查看预约</div> -->
        </div>
      </van-dialog>
      <!-- <van-dialog v-model="showSucced2" :showConfirmButton="false">
        <div class="order">支付遇到问题？重新支付</div>
        <div class="last">
          <button
            class="left"
            @click="() => this.$router.push('/consult/index')"
          >
            我已支付
          </button>
        </div>
      </van-dialog> -->
    </div>
  </div>
</template>
<script>
import { consultApi } from "../../api/consult";
import SelectMember from "../selectMember/index";
import QRCode from "qrcodejs2";
import { judge } from "../../utils/judge.js";
export default {
  data() {
    return {
      timer: "",
      showSucced: false,
      showSucced2: false,
      showQrcode: false,
      showMember: false,
      //时间段
      timeDate: [],
      lastTimeDate: [],
      timeDate2: [],
      lastTimeDate2: [],
      value1: 0,
      form: {
        //咨询成员id
        memberId: "",
        //日期
        YTimeDate: "",
      },
      //咨询id
      consultId: "",
      show: false,
      nickName: "",
      options: [],
      memberMorDate: [],
      memberAftDate: [],
      spanIndex1: [],
      spanIndex2: [],
      cost: 0,
      allcost: 0,
      length1: 0,
      length2: 0,
      device: "",
      order: "",
    };
  },
  watch: {
    // form: {
    //   handler(newValue, oldValue) {
    //     console.log(newValue);
    //     this.getMemberDate();
    //   },
    //   deep: true,
    // },
    lastTimeDate: {
      handler(newValue, oldValue) {
        console.log("newValue", newValue);
        this.length1 = this.lastTimeDate.length;
        this.allcost = this.cost * (this.length1 + this.length2);
        console.log("memberAftDate", this.lastTimeDate);
        console.log("this.allcost", this.allcost);
      },
    },
    lastTimeDate2: {
      handler(newValue, oldValue) {
        console.log("newValue", newValue);
        this.length2 = this.lastTimeDate2.length;
        this.allcost = this.cost * (this.length1 + this.length2);
      },
    },
  },
  created() {
    this.consultId = this.$route.query ? this.$route.query.id : "";
    // this.id = this.$route.query ? this.$route.query.categoryId : "";
    this.getClassify();
    this.getName();

    console.log(this.$route.query);
  },

  methods: {
    quexiao() {
      clearInterval(this.timer);
    },
    queding() {
      clearInterval(this.timer);
    },
    getMorTime(index) {
      var arrIndex = this.spanIndex1.indexOf(index);
      if (arrIndex > -1) {
        this.spanIndex1.splice(arrIndex, 1);
      } else {
        this.spanIndex1.push(index);
      }
      console.log("this.spanIndex11111", this.spanIndex1);
      this.spanIndex1.map((res) => {
        this.timeDate.push(this.memberMorDate[res]);
      });
      console.log("this.spanIndex1.length", this.spanIndex1.length);
      console.log(1111);
      console.log("this.timeDate", this.timeDate);
      let arr = [];
      if (this.timeDate.length > 0) {
        this.timeDate.map((item) => {
          arr.push(item.time_slot);
          console.log("item.time_slot", arr);
          this.lastTimeDate = arr;
        });
      } else {
        this.lastTimeDate = [];
      }
      this.timeDate = [];
      // this.spanIndex1 = []
      console.log("lastTimeDate", this.lastTimeDate);
    },
    getAftTime(index) {
      let arrIndex = this.spanIndex2.indexOf(index);
      if (arrIndex > -1) {
        this.spanIndex2.splice(arrIndex, 1);
      } else {
        this.spanIndex2.push(index);
      }
      this.spanIndex2.map((res) => {
        this.timeDate2.push(this.memberAftDate[res]);
      });
      let brr = [];
      if (this.timeDate2.length > 0) {
        this.timeDate2.map((item) => {
          brr.push(item.time_slot);
          console.log("item.time_slot", brr);
          this.lastTimeDate2 = brr;
        });
      } else {
        this.lastTimeDate2 = [];
      }
      this.timeDate2 = [];
      console.log("lastTimeDate2", this.lastTimeDate2);
    },
    // 确认付款
    confirmPay() {
      let allDate = [].concat(this.lastTimeDate, this.lastTimeDate2);
      console.log("allDate", allDate);
      var ua = navigator.userAgent.toLowerCase();
      let isWxWork = ua.match(/WxWork/i) == "wxwork";
      if (!isWxWork) {
        this.$toast("请在企业微信客户端进行支付");
        return;
      }
      let params = {
        serviceId: this.consultId,
        categoryId: this.value1,
        memberId: this.form.memberId,
        timeDate: this.form.YTimeDate,
        timeSlot: allDate,
        device: this.device,
      };
      consultApi.creatOrder(params).then((res) => {
        this.order = res.data.orderNo;
        let orderId = res.data.id;
        console.log("this.order", this.order);
        if (res.code == 0) {
          if (this.device == "m") {
            let url = encodeURIComponent(
              `${
                location.protocol + "//" + location.host
              }/payPopu/index?id=${orderId}`
            );
            location.href = res.data.wechctUrl + `&redirect_url=${url}`;
            // location.href = res.data.wechctUrl;
          } else {
            this.showQrcode = true;
            this.$nextTick(() => {
              new QRCode(this.$refs.qrCodeDiv, {
                text: res.data.wechctUrl, //url地址  文本等需要转换为二维码的内容
                width: 80,
                height: 80,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
              });
            });
            var that = this;
            this.timer = setInterval(function () {
              console.log("order", that.order);
              consultApi.queryStatus({ orderNo: that.order }).then((res) => {
                console.log("res", res);
                if (res.data == 3) {
                  that.showSucced = true;
                  that.showQrcode = false;
                  clearInterval(that.timer);
                }
                // else {
                //   that.$toast(res.sMsg)
                // }
              });
            }, 1000);
          }
        } else {
          this.$toast(res.sMsg);
        }
      });
    },
    // 获取成员时间段
    getMemberDate() {
      consultApi
        .memberDate({
          serviceId: this.consultId,
          categoryId: this.value1 ? this.value1 : "",
          memberId: this.form.memberId,
          timeDate: this.form.YTimeDate,
        })
        .then((res) => {
          console.log("成员时间段", res);
          this.memberMorDate = res.data.forenoon_time;
          this.memberAftDate = res.data.afternoon_time;
        });
    },
    formatDate(date) {
      console.log("date", date);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.form.YTimeDate = this.formatDate(date);
      this.spanIndex1 = [];
      this.spanIndex2 = [];
      this.lastTimeDate = [];
      this.lastTimeDate2 = [];
      this.getMemberDate();
      console.log("timeDate", this.form.YTimeDate);
    },
    getName() {
      this.nickName = this.$route.query.name;
      this.form.memberId = this.$route.query.memberId;
      this.cost = this.$route.query.cost;
      console.log("this.name", this.ncikName);
    },
    getClassify() {
      this.device = judge();
      console.log("this.device", this.device);
      this.consultId = this.$route.query.id;
      consultApi.consultClassify({ id: this.consultId }).then((res) => {
        console.log(res);
        res.data.unshift({
          name: "请选择分类",
          id: 0,
        });
        this.options = res.data.map((item) => {
          return { text: item.name, value: item.id };
        });
        this.value1 = this.$route.query.categoryId
          ? parseInt(this.$route.query.categoryId)
          : parseInt(0);
        console.log("this.value", this.value1);
      });
    },
    skip() {
      this.$router.push({
        path: "/selectMember/index",
        query: {
          lastTimeDate: this.lastTimeDate ? this.lastTimeDate[0] : "",
          lastTimeDate2: this.lastTimeDate2 ? this.lastTimeDate2[0] : "",
          id: this.consultId,
          categoryId: this.value1,
          cost: this.cost,
        },
      });
    },
  },
  components: { SelectMember },
};
</script>
<style lang="less" scoped>
/deep/ .van-dialog {
  height: 190px;
  text-align: center;
  width:200px !important
}
/deep/ .van-dropdown-menu__bar {
  right: 8px;
  height: 42px;
  box-shadow: none;
}
/deep/ .van-popup--top {
  left: 0 !important;
}
@media screen and(max-width: 1024px){
/deep/ .van-popup--bottom {
  left: 0 !important;
}
}
/deep/ .van-cell {
  padding-left: 0;
}
/deep/ .multiselect__tags {
  margin: 0;
  padding: 0;
  border: none;
  height: 46px;
  line-height: 46px;
  height: auto;
}
/deep/ .multiselect__single {
  vertical-align: middle;
}
/deep/ .multiselect {
  height: auto;
  width: 70%;
}
body,
html {
  min-height: 100%;
  height: 100%;
}
@media screen and(min-width: 1024px){
.member{
  width:60%;
  height: 24px;
  padding-left: 54%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.selectConsult{
  min-height: 500px !important;
}
.van-popup--bottom{
  left:18% !important;
}
} 
.order {
    margin-top: 10px;
    width: 100%;
    min-height: 120px;
    background-color: #fff;
  }
.qrcode {
  margin: 0 auto;
  width: 80px;
  height: 80px;
}
.gg {
  background-color: #dadada !important;
  color: #fff !important;
}
.selectConsult {
  height: 100%;
  min-height: 100%;
  background-color: #f9f9f9;
}
.banxin {
  height: 100%;
  min-height: 100%;
}
.section {
  height: 46px;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  line-height: 46px;
}
span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
select {
  margin-top: 8px;
  width: 264px;
  height: 29px;
  background: #f9f9f9;
  border-radius: 4px;
  border: none;
  border: 1px solid #969696;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  option {
    background-color: pink;
    width: 1px;
  }
}
@media screen and(max-width: 1024px){
  .member {
  width: 70%;
  height: 24px;
  padding-left: 54%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
} 

.date {
  margin-top: 10px;
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.time {
  margin-top: 10px;
  width: 100%;
  min-height: 46px;
  line-height: 46px;
  background: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
  .m16 {
    min-height: 100px;
    p {
      margin: 0;
      height: 34px;
    }
    button {
      margin-left: 5px;
      width: 94%;
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
      font-size: 14px;
      color: #000000;
      border: 1px solid #979797;
      font-family: PingFangSC-Regular, PingFang SC;
      background-color: #fff;
    }
    .notActive {
    }
    .active {
      font-size: 14px;
      border: none;
      background-color: #3974c6;
      color: #fff;
      margin-left: 5px;
    }
  }
}
.pay {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  background: #ffffff;
  font-size: 14px;
  color: #2d3042;
  .payRight {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #e84a10;
  }
}
.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button,
  .left {
    border: none;
    width: 106px;
    height: 32px;
    background: #3974c6;
    border-radius: 16px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>